import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, ProductService, Product, SimpleProduct, AdminService } from "@mypxplat/xplat/core";
import { format } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

interface KeyGenArgs {
  productKey: {
    productId: number;
    options: string;
    installCount: string | number;
    timeOutDays?: number;
  };
  variant?: string;
  numKeys: string;
  expirationDate?: string;
  bundledProductIds?: string;
}

@Component({
  selector: "myp-generate-keys",
  templateUrl: "generate-keys.component.html",
})
export class GenerateKeysComponent extends BaseComponent implements OnInit {
  public loading: boolean = true;
  public generating: boolean = false;
  public products: Array<SimpleProduct>;
  public productMap: any = {};
  public args: KeyGenArgs = {
    productKey: {
      productId: null,
      options: null,
      installCount: "5",
      timeOutDays: null,
    },
    variant: "",
    numKeys: "1",
    expirationDate: null,
  };

  public selectedBundledProducts: Array<any>;
  public selectedBundledProduct: any;
  public keyGenProducts: Array<any>;
  public keyGenNfrProducts: Array<any>;
  public nfrProductTitleArray: Array<string>;
  public selectedNfrProduct: any;
  public productTitleArray: Array<string>;
  public allProductTitleArray: Array<string>;
  public allProductTitlesWithMetadataArray: Array<any>;
  public selectedProduct: any;
  public productKeyOptions: any;
  public generatedKeys: Array<string>;
  public setExpiration: boolean = false;
  public keyGenerationModes = [
    {
      name: "Simple",
      selected: true,
    },
    {
      name: "Advanced",
    },
    {
      name: "Send NFR",
    },
  ];
  public displayedKeyGenerationMode = this.keyGenerationModes[0].name;
  public sendNfrName: string;
  public sendNfrEmail: string;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    private _spin: NgxSpinnerService,
    private _clipboardService: ClipboardService
  ) {
    super(userService);
  }

  ngOnInit() {
    // this._spin.show();
    this.adminService.keygenProducts().subscribe((result: any) => {
      this.keyGenProducts = result.products;
      this.sortKeyGenProducts();
      this.keyGenNfrProducts = result.nfrProducts;
      this.productTitleArray = this.keyGenProducts.map((product) => product.title);
      this.nfrProductTitleArray = this.keyGenNfrProducts.map((product) => product.title);
      this.productKeyOptions = result.keyOptions;
      this.selectProduct(this.keyGenProducts[0].title);
      if (!this.products) {
        const software$ = this.adminService.productList("software", true);
        const extension$ = this.adminService.productList("extensions", true);
        const subscription$ = this.adminService.productList("subscription", true).pipe(
          map((result) => {
            let realProducts = (<any>result).filter((item) => item.title.indexOf("base") > -1);
            realProducts.forEach((product) => {
              if (product.title.indexOf("Monthly") > -1) {
                product.title = "Studio One Pro+ Monthly";
              } else {
                product.title = "Studio One Pro+ Annual";
              }
            });
            return realProducts;
          })
        );
        forkJoin([software$, extension$, subscription$]).subscribe((results) => {
          this.products = [].concat(...results);
          this.products.sort((a, b) => (a.title > b.title ? 1 : -1));
          this.products.forEach((product) => {
            this.productMap[product.id] = product;
          });
          this.allProductTitleArray = this.products.map((product) => product.title);
          this.allProductTitlesWithMetadataArray = this.products.map((product) => {
            return { value: product.title, metadata: { type: product.type } };
          });
          if (this.displayedKeyGenerationMode == "Advanced") this.selectProduct(this.allProductTitleArray[0]);
          // this._spin.hide();
          this.loading = false;
        });
      }
    });
  }

  sortKeyGenProducts() {
    let s17Products = this.keyGenProducts.filter((product) => product.type == "Studio One Pro 7" && product.title.indexOf("Upgrade") == -1);
    let s17UpgradeProducts = this.keyGenProducts.filter((product) => product.type == "Studio One Pro 7" && product.title.indexOf("Upgrade") > -1);
    let s16Products = this.keyGenProducts.filter((product) => product.type == "Studio One 6" && product.title.indexOf("Upgrade") == -1);
    let s16UpgradeProducts = this.keyGenProducts.filter((product) => product.type == "Studio One 6" && product.title.indexOf("Upgrade") > -1);
    let s15Products = this.keyGenProducts.filter((product) => product.type == "Studio One 5" && product.title.indexOf("Upgrade") == -1);
    let s15UpgradeProducts = this.keyGenProducts.filter((product) => product.type == "Studio One 5" && product.title.indexOf("Upgrade") > -1);
    let s1PlusProducts = this.keyGenProducts.filter((product) => product.title.indexOf("Studio One+") > -1 || product.title.indexOf("Studio One Pro+") > -1);
    this.keyGenProducts = [...s1PlusProducts, ...s17Products, ...s17UpgradeProducts, ...s16Products, ...s16UpgradeProducts];
  }

  selectSection(section) {
    this.displayedKeyGenerationMode = section;
    this.selectProduct(section == "Advanced" ? this.allProductTitleArray[0] : this.productTitleArray[0]);
  }

  selectBundledProduct(title) {
    if (title) {
      let selection = this.products.find((product) => product.title == title);
      if (!this.selectedBundledProducts) this.selectedBundledProducts = [];
      // only add it if its not already in the list
      if (!this.selectedBundledProducts.find((product) => product?.id == selection.id)) this.selectedBundledProducts.push(selection);
      this.selectedBundledProduct = undefined;
    } else {
      this.selectedBundledProduct = undefined;
    }
  }

  removeBundledProduct(item) {
    this.selectedBundledProducts.splice(this.selectedBundledProducts.indexOf(item), 1);
  }

  selectProduct(title) {
    if (title) {
      let source = this.keyGenProducts;
      if (this.displayedKeyGenerationMode == "Advanced") source = this.products;
      this.selectedProduct = source.find((product) => product.title === title);
      this.args.productKey.productId = this.selectedProduct.id;
      if (this.selectedProduct.options?.length) {
        this.args.productKey.options = this.selectedProduct.options[0];
        if (this.args.productKey.options == "OEM") {
          this.args.variant = this.productKeyOptions.oemVariants[0];
        } else if (this.args.productKey.options == "NFR") {
          this.args.variant = this.productKeyOptions.nfrVariants[0];
        }
      } else if (this.displayedKeyGenerationMode == "Advanced") {
        this.args.productKey.options = this.productKeyOptions.options["4"];
      }
    } else {
      this.selectedProduct = undefined;
      this.args.productKey.productId = null;
      this.args.productKey.options = null;
      this.args.variant = null;
    }
  }

  selectNfrProduct(title) {
    if (title) {
      let source = this.keyGenNfrProducts;
      this.selectedNfrProduct = source.find((product) => product.title === title);
      this.args.productKey.productId = this.selectedNfrProduct.id;
      if (this.selectedNfrProduct.options?.length) {
        this.args.productKey.options = this.selectedNfrProduct.options[0];
        this.args.variant = this.productKeyOptions.nfrVariants[0];
      } else if (this.displayedKeyGenerationMode == "Advanced") {
        this.args.productKey.options = this.productKeyOptions.options["4"];
      }
    } else {
      this.selectedNfrProduct = undefined;
      this.args.productKey.productId = null;
      this.args.productKey.options = null;
      this.args.variant = null;
    }
  }

  generateKeys() {
    if (this.args.productKey.timeOutDays && this.args.expirationDate) delete this.args.expirationDate;
    if (this.args.expirationDate) this.args.expirationDate = format(new Date(this.args.expirationDate), "yyyy-MM-dd'T'HH:mm:ss");
    if (this.selectedBundledProducts && this.selectedBundledProducts.length) {
      this.args.bundledProductIds = this.selectedBundledProducts.map((product) => product.id).toString();
    }
    this.generating = true;
    this.adminService.generateKeys(this.args).subscribe((result: any) => {
      this.generating = false;
      this.selectedBundledProducts = undefined;
      this.generatedKeys = result;
      if (this.displayedKeyGenerationMode == "Send NFR" && this.sendNfrEmail && this.sendNfrName) {
        let html = this.getMessageHtml();
        let args: any = {
          subject: "A new software key, just for you!",
          messageHtml: html,
          from: "no-replies@presonus.com",
          to: this.sendNfrEmail,
        };
        this.adminService.sendEmail(args).subscribe((result) => {});
      }
    });
  }

  copyKey(key) {
    this._clipboardService.copy(key);
  }

  downloadKeys() {
    const array = this.generatedKeys; // Assuming this.keys is your array of strings
    const csvContent = array.join("\n"); // Join each string in the array with a newline character

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element, use it to download the blob, and then remove it
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Feature check for download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "keys-export.csv"); // Set the file name for the CSV
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up by revoking the Blob URL
    }
  }

  getMessageHtml() {
    let html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>A new software key, just for you!</title>
          <style>
              .email-container {
                  max-width: 600px;
                  margin: 20px auto;
                  padding: 20px;
                  border-radius: 8px;
              }
              .content { padding: 20px 0; line-height: 1.6; }
              .author-section {
                  display: flex;
                  align-items: center;
                  padding: 20px 0;
                  margin-bottom: 20px;
              }
              .author-photo {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 15px;
              }
              .author-photo img {
                  width: 100%;
                  height: auto;
                  display: block;
              }
              .author-name { font-weight: bold; font-size: 16px; }
              .button {
                  background-color: #007bff;
                  color: #ffffff;
                  padding: 10px 12px;
                  border-radius: 30px;
                  text-decoration: none;
                  font-size: 16px;
              }
              .button:hover { background-color: #0056b3; color: white }
          </style>
      </head>
      <body>
          <div class="email-container">
              <div><strong>To obtain your ${this.selectedNfrProduct.title} NFR, please do the following:</strong></div>
              <div class="content">
                <p>Visit <a href="https://my.presonus.com">https://my.presonus.com</a> and register a new account or log in to your existing account.</p>
                <p>Once logged in, click on "register new software" and enter the following Product Key:</p>
                <p style="color: 007bff; margin: 20px 0px;">${this.generatedKeys[0]}</p>
                <p>Once the product is registered, you will see the download links. Download and install ${this.selectedNfrProduct.title}, then launch the software and follow the activation instructions.</p>
              </div>
            
          </div>
      </body>
      </html>
    `;
    return html;
  }
}
