import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from "@angular/core";

import { CommentBaseComponent } from "@mypxplat/xplat/features";
import { ContentService, EventBusService, File, UserService, WindowService, WorkspaceService, environment, Notification, HelperService, StorageService, StorageKeys } from "@mypxplat/xplat/core";
import { MessageInputComponent } from "../message-input/message-input.component";
import { take, takeUntil } from "rxjs/operators";
import { AppService, AvoService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { CreateJiraTicketComponent, EditCommentComponent, HtmlOverlayComponent, SearchContentComponent, SimpleSignupComponent, SphereSubscribeStripeComponent } from "../modals";
import { ClipboardService } from "ngx-clipboard";
import { Router } from "@angular/router";
import { WorkspaceUploadComponent } from "@mypxplat/xplat/web/features";
import { FileUploader } from "ng2-file-upload";
@Component({
  selector: "myp-comment",
  templateUrl: "comment.component.html",
})
export class CommentComponent extends CommentBaseComponent implements OnInit {
  @Input() betaStringId: boolean;
  @Input() isCommunityPost: boolean;
  @Input() index: boolean;
  @Input() reviewing: boolean;
  @Input() expandRepliesByDefault: boolean = false;
  @Input() forceDark: boolean = false;
  @Input() queued: boolean = false;
  @Input() displayedSection: any;
  @Output() replied: EventEmitter<any> = new EventEmitter();
  @Output() flagged: EventEmitter<any> = new EventEmitter();
  @Output() unflagged: EventEmitter<any> = new EventEmitter();
  @Output() liked: EventEmitter<any> = new EventEmitter();
  @Output() flagCleared: EventEmitter<any> = new EventEmitter();
  @Output() deleteIndexSet: EventEmitter<any> = new EventEmitter();
  @Output() channelSelected: EventEmitter<any> = new EventEmitter();
  @Output() topicSelected: EventEmitter<any> = new EventEmitter();
  @Output() postEdited: EventEmitter<any> = new EventEmitter();
  @Output() editMessageClicked: EventEmitter<any> = new EventEmitter();
  @Output() deleteMessageClicked: EventEmitter<any> = new EventEmitter();
  @Output() adminDeleteMessageClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild("replyInput", { static: false }) replyInput: MessageInputComponent;
  public replyingToReply: any;
  public replyingToComment: any;
  public showingAllReplies: boolean = false;
  public shouldShowLikesList: any = false;
  public likesContainerHovering: boolean = false;

  public pinReplyingToPost: boolean = true;

  public uploadingFile: any = false;
  public uploadingFileProgress: number;

  public uploadingFiles: any = false;
  public uploadedAttachments: Array<File>;

  public queuedPosts: Array<any> = [];
  public uploader: FileUploader;
  public hasBaseDropZoneOver: any;

  public articleMap: any = {};

  public betaManagerEditingChannels: boolean = false;
  public shouldShowChannelSelector: boolean;

  @Input()
  get replies(): Array<any> {
    return this._replies;
  }
  set replies(replies: Array<any>) {
    this._replies = replies;
    this.buildReplies();
  }
  private _replies = [];

  @Input()
  get comment(): any {
    this._comment.iLike = this._comment.likes && this._comment.likes.includes(this.user?.id) ? true : false;
    return this._comment;
  }
  set comment(comment: any) {
    this._comment = comment;
  }
  private _comment: any = {};
  public removeEventListener: () => void;

  public isBetaManager: boolean = false;
  public markingAsReviewed: boolean = false;
  public betaReviewDetails: any = {
    reviewed_by: {},
    reviewed_at: new Date(),
    review_text: "",
    jira_ticket_number: "",
    jira_ticket_link: "",
  };
  public isPostDetail: boolean = false;
  public betaManagerNotificationPreferences: any;
  constructor(
    userService: UserService,
    private _win: WindowService,
    private _zone: NgZone,
    private _eventbusService: EventBusService,
    public contentService: ContentService,
    public appService: AppService,
    public communityService: WebCommunityService,
    public workspaceService: WorkspaceService,
    private _clipboardService: ClipboardService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
    public avoService: AvoService,
    public renderer: Renderer2,
    private elementRef: ElementRef,
    public helperService: HelperService,
    public betaService: BetaService,
    public storageService: StorageService
  ) {
    super(userService);
  }

  ngOnInit() {
    // inspect the url to see if they on post detail
    this.isPostDetail = this.router.url.includes("/community/post/") || this.router.url.includes("/beta/betapost");
    this.isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
    super.ngOnInit();
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    this.communityService.buildHtmlComment(this.comment);

    if (this.comment.file && this.workspaceService.audioFiles[this.comment.file.extension] && this.comment.file.filesize < 10000000) {
      this.comment.shouldLoadWaveform = true;
    }
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, "click", (event) => {
      this.handleAnchorClick(event);
    });
    if (this.comment.articles) {
      this.comment.articles.forEach((item) => {
        this.articleMap[item.article.id] = item;
      });
    }
    if (this.comment.beta_review_details)
      this.betaReviewDetails = {
        reviewed_by: { ...this.comment.beta_review_details.reviewed_by },
        reviewed_on: this.comment.beta_review_details.reviewed_on,
        review_text: this.comment.beta_review_details.review_text,
        jira_ticket_number: this.comment.beta_review_details.jira_ticket_number,
        jira_ticket_link: this.comment.beta_review_details.jira_ticket_link,
      };
    if (this.betaStringId && !this.comment.beta_status) this.comment.beta_status = "none";
    if (this.betaStringId && !this.comment.beta_type) this.comment.beta_type = "none";

    if (this.betaStringId) {
      this.pinReplyingToPost = false;
      this.betaService.getBetaNotificationManagerPreferences(this.betaStringId).subscribe((result: any) => {
        this.betaManagerNotificationPreferences = result;
      });
    }
  }

  handleAnchorClick(event) {
    if (event.target && event.target.dataset && event.target.dataset.mentionid) {
      if (event.target.dataset.mentionid != "studio_one_experts") this.viewProfile(event.target.dataset.mentionid);
    } else if (event.target && event.target.dataset && event.target.dataset.articleid) {
      this.showArticle(this.articleMap[event.target.dataset.articleid]);
    } else if (event.target && event.target.dataset && event.target.dataset.sectionid && event.target.dataset.categoryid) {
      let queryParams = { category: event.target.dataset.categoryid, section: event.target.dataset.sectionid };
      this.router.navigate(["support/articles"], { queryParams: queryParams });
    } else if (event.target && event.target.dataset && event.target.dataset.categoryid) {
      let queryParams = { category: event.target.dataset.categoryid };
      this.router.navigate(["support/articles"], { queryParams: queryParams });
    } else if (event.target && event.target.dataset && event.target.dataset.videoid) {
      this.watchVideo(event.target.dataset.videoid);
    } else if (event.target && event.target.dataset && event.target.dataset.lessonid) {
      this.router.navigate(["learn/lesson/" + event.target.dataset.lessonid]);
    } else if (event.target && event.target.dataset && event.target.dataset.courseid) {
      this.router.navigate(["learn/course/" + event.target.dataset.courseid]);
    } else if (event.target && event.target.dataset && event.target.dataset.hash) {
      this.router.navigate(["/share/" + event.target.dataset.hash]);
    }
  }

  showAttach(type) {
    const modalRef = this.appService.showModal(SearchContentComponent, { size: "lg" });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.contentSelected.pipe(take(1)).subscribe((result) => {
      if (type == "articles") {
        this.replyInput.selectedArticlesInMessage.push({ selector: false, editorSelector: false, article: result });
      } else {
        this.replyInput.selectedLearnContentInMessage.push({ selector: false, editorSelector: false, content: result });
      }
    });
  }

  detailClick() {
    if (this.betaStringId) {
      this.router.navigate([`/beta/betapost/${this.comment.id}`], { queryParams: { betaStringId: this.betaStringId } });
    } else if (this.userService.isEmployee && this.router.routerState.snapshot.url != "/community/post/" + this.comment.id) {
      this.router.navigate([`/community/post/${this.comment.id}`]);
    }
  }

  removeArticle(article, e) {
    this.replyInput.removeSelectedArticle(article);
    e.stopPropagation();
  }

  showArticle(article) {
    let modalRef = this.appService.showModal(HtmlOverlayComponent, { size: "lg" });
    modalRef.componentInstance.title = article.article.name;
    modalRef.componentInstance.html = '<style type="text/css">img { max-width: 100%; }</style>' + article.article.body;
  }

  goToPublicFile(item) {
    this.router.navigate(["/share/" + item.publicFile.hash]);
  }

  removeLearnContent(item, e) {
    this.replyInput.removeSelectedLearnContent(item);
    e.stopPropagation();
  }

  removePublicFile(item, e) {
    this.replyInput.removePublicFile(item);
    e.stopPropagation();
  }

  removeEmbed(embed, e) {
    this.replyInput.removeEmbed(embed);
    e.stopPropagation();
  }

  deletePinnedPost() {
    if (confirm("Are you sure you want to remove this pinned post?")) {
      this.communityService.deletePost(this.comment.id, false, "beta_community_posts").then(() => {
        this._eventbusService.emit(this._eventbusService.types.betaAnnouncementUpdated, true);
      });
    }
  }

  unpinBetaPost() {
    if (confirm("Are you sure you want to unpin this post? This will place the post in the feed in the order it was created.")) {
      this.communityService.updatePost(this.comment.id, { pinned: false }, this.betaStringId ? `beta_community_posts` : "community_posts").then(() => {
        this._eventbusService.emit(this._eventbusService.types.betaAnnouncementUpdated, true);
      });
    }
  }

  buildReplies() {
    if (this.replies) {
      if (this.expandRepliesByDefault && this.replies.length) this.comment.replying = true;
      if (this.replies.length) {
        this.replies.sort((a, b) => {
          return a.created < b.created ? -1 : 1;
        });
      }
      this.replies.forEach((reply) => {
        reply.iLike = reply.likes && reply.likes.includes(this.user?.id) ? true : false;
        if (!reply.htmlMessage) {
          this.communityService.buildHtmlComment(reply);
        }
        if (reply.articles) {
          reply.articles.forEach((item) => {
            this.articleMap[item.article.id] = item;
          });
        }
      });
    }
  }

  playAudio(file, commentID, event) {
    if (event) event.stopPropagation();
    if (this.communityService.playingAudioFile && this.communityService.playingAudioFile.id == file.id) {
      this._eventbusService.emit(this._eventbusService.types.playAudio, false);
    } else {
      this._eventbusService.emit(this._eventbusService.types.playAudio, {
        ...file,
        hideDownloadOption: true,
        routeToSource: this.router.url,
        sourcePostID: commentID,
      });
    }
  }

  viewProfile(id) {
    if (this.isCommunityPost) {
      this.router.navigate(["/profile", id]);
    }
  }

  downloadFile(file) {
    let link = document.createElement("a");
    link.href = file.url;
    link.download = file.url;
    link.click();
  }

  audioPlayed(args) {
    if (this.communityService.currentlyPlayingWaveformComponent) this.communityService.currentlyPlayingWaveformComponent.toggleAudio();
  }

  toggleReplyUI(comment, replyingToReply?, forceExpand?) {
    if (this.user) {
      if (this.isCommunityPost && !this.communityService.profile?.public && !this.betaStringId) {
        if (this.user?.active_subscription) {
          alert("Your profile needs to be set to public in order to reply to public posts.");
        } else {
          this.upgrade();
        }
      } else {
        if (replyingToReply) {
          this.replyingToReply = replyingToReply;
          if (!this.betaStringId) {
            this._win.setTimeout(() => {
              this.replyInput.selectMention(replyingToReply.author, true);
            }, 200);
          }
        } else {
          this.replyingToComment = comment;
        }
        if (forceExpand) {
          comment.replying = true;
        } else {
          comment.replying = !comment.replying;
        }
        this.changeDetector.detectChanges();
        if (comment.replying) {
          let inputContainerEl = document.getElementById(comment.key + "_reply_input_container");
          if (inputContainerEl && inputContainerEl.scrollIntoView) {
            inputContainerEl.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }

          this._win.setTimeout(() => {
            this.replyInput.focusMessageInput();

            let existingReplyDrafts = this.storageService.getItem(StorageKeys.POST_REPLY_DRAFT_MAP);
            if (existingReplyDrafts[this.comment.id]) {
              this.replyInput.setMessageValue(existingReplyDrafts[this.comment.id].body);
            }
          }, 400);
        }
      }
    } else {
      this.appService.promptSimpleSignup(SimpleSignupComponent).then(
        () => {
          this.toggleReplyUI(comment, replyingToReply, forceExpand);
        },
        (error) => {}
      );
    }
  }

  watchVideo(id) {
    let video = this.contentService.videosById[id];
    this._eventbusService.emit(this._eventbusService.types.playVideo, {
      id: video.platform_id,
      title: video.title,
      type: video.platform,
      thumb: video.image,
      presonus_id: video.id,
    });
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  clearReplyToReply(comment) {
    this.replyingToReply = undefined;
    this.replyInput.setMessageValue("");
    comment.replying = false;
    this.changeDetector.detectChanges();
  }

  clearReplyUI(comment) {
    this.replyInput.setMessageValue("");
    this.replyingToComment = undefined;
    this.replyingToReply = undefined;
    comment.replying = false;
    this.changeDetector.detectChanges();
  }

  droppedFile(args) {
    if (this.isCommunityPost) {
      this.attachFile(args);
      this.uploader.clearQueue();
    }
  }

  sendReply(args, comment) {
    if (
      (this.isCommunityPost && this.pinReplyingToPost && !this.comment.pins) ||
      (this.isCommunityPost && this.comment.pins && !this.comment.pins.includes(this.userService.user?.id)) ||
      (this.isCommunityPost && !this.pinReplyingToPost && this.comment.pins && this.comment.pins.includes(this.userService.user?.id))
    ) {
      this.pinPost();
    }
    let msg = args.value;
    if (this.isCommunityPost) {
      if ((msg && msg.trim()) || (this.uploadedAttachments && this.uploadedAttachments.length)) {
        let replyArgs: any = {
          author: {
            name: this.userService.user?.firstName + " " + this.userService.user?.lastName,
            photo: this.userService.user?.photoURL,
            id: this.userService.user?.id,
          },
          parentID: comment.key,
          created: new Date(),
        };
        if (comment.channels) {
          replyArgs.channels = comment.channels;
        }
        if (comment.topics) {
          replyArgs.topics = comment.topics;
        }
        if (this.betaStringId) replyArgs.beta_string_id = this.betaStringId;
        if (this.replyInput.selectedMentionsInMessage && this.replyInput.selectedMentionsInMessage.length) {
          let realMentions = [];
          this.replyInput.selectedMentionsInMessage.forEach((item) => {
            if (msg.indexOf(item.selector) > -1 && item.user?.id) {
              realMentions.push({
                id: item.user?.id,
                selector: item.selector,
                editorSelector: item.editorSelector,
              });

              msg = msg.replace(item.editorSelector, item.selector + " ");
              msg = this.helperService.htmlToText(msg);
            }
          });
          replyArgs.mentions = realMentions;
          this.replyInput.selectedMentionsInMessage = [];
        }

        if (this.replyInput.selectedArticlesInMessage && this.replyInput.selectedArticlesInMessage.length) {
          let articleEntites = [];
          this.replyInput.selectedArticlesInMessage.forEach((item) => {
            articleEntites.push({
              article: item.article,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
            msg = msg.replace(item.editorSelector, item.selector + " ");
          });
          replyArgs.articles = articleEntites;
        }

        if (this.replyInput.selectedLearnContentInMessage && this.replyInput.selectedLearnContentInMessage.length) {
          let learnContentEntities = [];
          this.replyInput.selectedLearnContentInMessage.forEach((item) => {
            learnContentEntities.push({
              content: item.content,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
            msg = msg.replace(item.editorSelector, item.selector + " ");
          });
          replyArgs.learn_content = learnContentEntities;
        }

        if (this.replyInput.publicFileLinksInMessage && this.replyInput.publicFileLinksInMessage.length) {
          let publicFileLinkEntities = [];
          this.replyInput.publicFileLinksInMessage.forEach((item) => {
            publicFileLinkEntities.push({
              publicFile: item.publicFile,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
            msg = msg.replace(item.editorSelector, item.selector + " ");
          });
          replyArgs.public_file_links = publicFileLinkEntities;
        }

        if (this.replyInput.selectedCategoriesSectionsInMessage && this.replyInput.selectedCategoriesSectionsInMessage.length) {
          let sectionCategoryEntities = [];
          this.replyInput.selectedCategoriesSectionsInMessage.forEach((item) => {
            sectionCategoryEntities.push({
              category_or_section: item.category_or_section,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
            msg = msg.replace(item.editorSelector, item.selector + " ");
          });
          replyArgs.categories_or_sections = sectionCategoryEntities;
        }

        const { messageEmbeds } = this.replyInput;
        if (messageEmbeds.length > 0) {
          replyArgs.embeds = messageEmbeds.map(({ id, type, subtype }) => {
            const embed = { id, type, subtype, src: "" };
            if (type == "youtube") {
              embed.src = `https://www.youtube.com/embed/${id}`;
            } else if (type == "soundcloud") {
              embed.src = `https://w.soundcloud.com/player/?url=${id}`;
            } else {
              embed.src = `https://open.spotify.com/embed/${subtype}/${id}`;
            }
            return embed;
          });
        }

        if (msg) replyArgs.message = msg;

        if (this.uploadingFiles && this.uploadingFiles.length) {
          alert("Please wait until your file is done uploading before sending this reply.");
          return;
        } else if (this.uploadedAttachments && this.uploadedAttachments.length) {
          replyArgs.files = this.uploadedAttachments;
        }

        this.removeAttachment();
        this.communityService.addPost(replyArgs, this.betaStringId ? `beta_community_posts` : "community_posts").then((result) => {
          let channels = this.comment.channels;
          let communityPostCreatedArgs: any = {
            siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
            channelName: this.getChannelName(channels),
            postType: "reply",
            postId: result.id,
          };
          if (this.uploadedAttachments) communityPostCreatedArgs.attachmentExtension = this.uploadedAttachments[0].extension;
          this.avoService.trackEvent().communityPostCreated(communityPostCreatedArgs);
          this.handleNotifications(replyArgs);
          this.clearReplyUI(comment);
        });
      }
    } else {
      this.replied.next({
        comment: comment,
        reply: msg,
        replyingTo: this.replyingToReply ? this.replyingToReply : undefined,
      });
      this.clearReplyUI(comment);
    }
  }

  handleNotifications(replyArgs) {
    let notifiedMap = {};
    let defaultTitle = this.betaStringId ? `${this.user?.firstName} replied to your post in the ${this.betaStringId} beta community.` : this.user?.firstName + " replied to your post.";
    let defaultUrl = this.betaStringId ? `https://my.presonus.com/beta/betapost/${this.comment.id}?betaStringId=${this.betaStringId}` : `https://my.presonus.com/community/post/${this.comment.id}`;
    let defaultReplyData = {
      author: replyArgs.author,
      message: this.helperService.htmlToText(replyArgs.message),
    };
    let notification: any = {
      title: defaultTitle,
      body: this.helperService.htmlToText(replyArgs.message),
      photo: this.userService.user?.photoURL,
      topics: ["community"],
      type: "community_post_reply",
      url: defaultUrl,
      button: "View Post",
      payload: {
        htmlBody: replyArgs.message,
        primary_post_id: this.comment.id,
        original_post: {
          id: this.comment.id,
          message: this.helperService.htmlToText(this.comment.message),
          author: this.comment.author,
        },
      },
    };
    if (this.comment.author.id != this.user?.id) {
      // dont send an email if youre replying to your own comment.
      if (!this.replyingToReply || this.replyingToReply.author.id != this.comment.author.id) {
        // dont send notification if this post is a reply to a reply the original author made, because they are getting a notification below.
        notification.payload.reply = defaultReplyData;
        this.userService.sendNotification({ notification: notification, user_ids: [this.comment.author.id], clients: ["rollup", "mobile", "email"] }).subscribe();
        notifiedMap[this.comment.author.id] = true;
      }
    }
    if (this.replyingToReply && this.replyingToReply.author.id != this.user?.id) {
      notification.payload.reply = defaultReplyData;
      this.userService.sendNotification({ notification: notification, user_ids: [this.replyingToReply.author.id], clients: ["rollup", "mobile", "email"] }).subscribe();
      notifiedMap[this.replyingToReply.author.id] = true;
    }

    if (replyArgs.mentions && replyArgs.mentions.length) {
      // add a notification for users who were mentioned, but not if the reply was directed to them or they were notified already.
      let mentionsToNotify = [];
      replyArgs.mentions.forEach((item) => {
        if (item.id != this.userService.user?.id && item.id != this.comment.author.id && item.id != this.replyingToReply?.author.id && !notifiedMap[item.id]) {
          mentionsToNotify.push(item.id);
          notifiedMap[item.id] = true;
        }
      });
      if (mentionsToNotify.length) {
        notification.title = this.user?.firstName + " mentioned you in a post.";
        notification.type = "community_post_mention";
        notification.payload.post = defaultReplyData;
        this.userService.sendNotification({ notification: notification, user_ids: mentionsToNotify, clients: ["rollup", "mobile", "email"] }).subscribe();
      }
    }
    if (this.comment.pins && this.comment.pins.length) {
      let pinsToNotify = [];
      this.comment.pins.forEach((id) => {
        // only notify them if they haven't been notified for some other reason, and the author isnt you.
        if (!notifiedMap[id] && id != replyArgs.author.id) {
          pinsToNotify.push(id);
        }
      });
      if (pinsToNotify.length) {
        notification.title = this.user?.firstName + " commented on a post you have pinned.";
        notification.payload.reply = defaultReplyData;
        this.userService.sendNotification({ notification: notification, user_ids: pinsToNotify, clients: ["rollup", "mobile", "email"] }).subscribe();
      }
    }

    if (this.betaStringId && this.betaManagerNotificationPreferences) {
      let notificationIds = [];
      // send out notifications to beta managers if applicable
      for (var i in this.betaManagerNotificationPreferences) {
        if (this.betaManagerNotificationPreferences[i].all_replies && !notifiedMap[i] && i != replyArgs.author.id) {
          notificationIds.push(i);
        }
      }
      if (notificationIds.length) {
        notification.title = `${this.user?.firstName} replied to a post in the ${this.betaStringId} beta program.`;
        notification.payload.reply = defaultReplyData;
        notification.type = "new_beta_post";
        this.userService.sendNotification({ notification: notification, user_ids: notificationIds, clients: ["rollup", "mobile", "email"] }).subscribe();
      }
    }
  }

  likesContainerEnter(data) {
    this.likesContainerHovering = true;
    this.likeUserNames(data.likes);
  }

  likesContainerLeave(data) {
    this.likesContainerHovering = false;
    this.shouldShowLikesList = false;
  }

  likeMouseEnter(data) {
    this.shouldShowLikesList = data.id;
    this.likeUserNames(data.likes);
  }

  likeMouseLeave() {
    this._win.setTimeout(() => {
      if (!this.likesContainerHovering) this.shouldShowLikesList = false;
    }, 200);
  }

  public activeHoveringLikeUserName: any;
  public activeHoveringLikeUserNameList = {};
  likeBubbleMouseEnter(userId) {
    if (this.communityService.cachedNames[userId]) {
      this.activeHoveringLikeUserName = this.communityService.cachedNames[userId];
      this.activeHoveringLikeUserNameList[userId] = this.communityService.cachedNames[userId];
    } else {
      this.communityService.getUserProfile(userId).then((result: any) => {
        this.activeHoveringLikeUserName = result.firstName + " " + result.lastName;
        this.activeHoveringLikeUserNameList[userId] = result.firstName + " " + result.lastName;
      });
    }
  }

  likeUserNames(userIds) {
    userIds.forEach((userId) => {
      if (this.communityService.cachedNames[userId]) {
        this.activeHoveringLikeUserNameList[userId] = this.communityService.cachedNames[userId];
      } else {
        this.communityService.getUserProfile(userId).then((result: any) => {
          this.activeHoveringLikeUserNameList[userId] = result.firstName + " " + result.lastName;
        });
      }
    });
  }

  likeBubbleMouseLeave() {
    this.activeHoveringLikeUserName = undefined;
  }

  likeBubbleMouseLeaveByFocus(idx, total) {
    this.activeHoveringLikeUserName = undefined;
    if (idx === total - 1) this.shouldShowLikesList = false;
  }

  msgOptions(msg) {
    let args: any = {
      title: this.helperService.stripHtml(msg.message).substring(0, 200) + "...",
      actions: [],
    };
    if (msg.author.id == this.user?.id) {
      args.actions.push("Edit " + (this.isCommunityPost ? "Post" : "Comment"));
      args.actions.push("Delete " + (this.isCommunityPost ? "Post" : "Comment"));
    } else {
      if (this.user?.active_subscription && !this.communityService.connectionMap[msg.author.id]) args.actions.push("Connect with " + msg.author.name);

      if (this.communityService.communityWizards[this.user?.id]) {
        args.actions.push("Delete " + (this.isCommunityPost ? "Post (Admin)" : "Comment (Admin)"));
        args.actions.push("Edit " + (this.isCommunityPost ? "Post (Admin)" : "Comment (Admin)"));
      }
    }

    if (this.user?.is_sphere_admin && this.isCommunityPost && !this.betaStringId) {
      args.actions.push(msg.sphere_only ? `Unmark as ${this.env.studioOnePlusBrandName} Only (Admin)` : `Mark as ${this.env.studioOnePlusBrandName} Only (Admin)`);
      args.actions.push("Moderate Posts by " + msg.author.name);
    }
    if (!this.betaStringId) {
      if (msg.flags && msg.flags.includes(this.userService.user?.id)) {
        args.actions.push("Unflag " + (this.isCommunityPost ? "Post" : "Comment"));
      } else {
        args.actions.push("Flag " + (this.isCommunityPost ? "Post" : "Comment"));
      }
    }

    if (this.user?.is_sphere_admin && msg.flags && msg.flags.length && !msg.cleared) args.actions.push("Clear Flag (Admin)");
    if (this.isCommunityPost && !msg.parentID) args.actions.push("Copy Direct Link to Post");
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Comment" || result == "Edit Post" || result == "Edit Post (Admin)" || result == "Edit Comment (Admin)") {
            if (this.isCommunityPost) {
              this.editPost(msg);
            } else {
              this.editMessageClicked.next(msg);
            }
          } else if (result == "Delete Message" || result == "Delete Post" || result == "Delete Comment" || result == "Delete Post (Admin)" || result == "Delete Comment (Admin)") {
            if (this.isCommunityPost) {
              this.deletePost(msg, result == "Delete Post (Admin)");
            } else {
              if (this.user?.is_admin && result == "Delete Post (Admin)") {
                this.adminDeleteMessageClicked.next(msg);
              } else {
                this.deleteMessageClicked.next(msg);
              }
            }
          } else if (result == "Flag Comment" || result == "Flag Post" || result == "Unflag Comment" || result == "Unflag Post") {
            this.flag(msg);
          } else if (result == "Clear Flag (Admin)") {
            this.clearFlag(msg);
          } else if (result == "Copy Direct Link to Post") {
            this.copyLink(msg);
          } else if (result == `Unmark as ${this.env.studioOnePlusBrandName} Only (Admin)` || result == `Mark as ${this.env.studioOnePlusBrandName} Only (Admin)`) {
            this.markUnmarkSphereOnly(msg);
          } else if (result.indexOf("Connect with") > -1) {
            this.communityService.addConnection(msg.author.id).subscribe((result) => {
              alert("Your connection request has been sent.");
            });
          } else if (result.indexOf("Moderate") > -1) {
            this.router.navigate(["/admin/user_posts"], { queryParams: { user_id: msg.author.id } });
          }
        }
      });
  }

  replyContentChanged(args) {
    this.updateReplyDrafts(args);
  }

  updateReplyDrafts(replyText) {
    let existingReplyDrafts = this.storageService.getItem(StorageKeys.POST_REPLY_DRAFT_MAP);
    if (!existingReplyDrafts) existingReplyDrafts = {};
    let rawTextReply = this.helperService.stripHtml(replyText);
    if (rawTextReply) {
      let draftArgs = {
        body: replyText,
      };
      existingReplyDrafts[this.comment.id] = draftArgs;
      this.storageService.setItem(StorageKeys.POST_REPLY_DRAFT_MAP, existingReplyDrafts);
    } else {
      delete existingReplyDrafts[this.comment.id];
      this.storageService.setItem(StorageKeys.POST_REPLY_DRAFT_MAP, existingReplyDrafts);
    }
  }

  copyLink(msg) {
    if (this.betaStringId) {
      this._clipboardService.copy(this.env.mypresonusUrl + "beta/betapost/" + msg.id + "?betaStringId=" + this.betaStringId);
    } else {
      this._clipboardService.copy(this.env.mypresonusUrl + "community/post/" + msg.id);
    }
  }

  showVideo(video) {
    this._eventbusService.emit(this._eventbusService.types.playVideo, {
      id: video.video_id,
      title: video.title,
      type: video.type,
      thumb: video.thumb,
    });
  }

  markUnmarkSphereOnly(comment) {
    this.communityService.markUnmarkSphereOnly(comment).then(() => {
      comment.sphere_only = comment.sphere_only ? false : true;
    });
  }

  flag(comment) {
    if (this.user) {
      if (comment.flags && comment.flags.includes(this.userService.user?.id)) {
        this.unflag(comment);
      } else {
        let args: any = {
          title: "Choose why you are flagging this post.",
          actions: ["Inappropriate Content", "Privacy Violation", "Personal Harassment", "Hateful Speech", "Copyright Violation", "Other"],
        };

        this._win.setTimeout(() => {
          this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
          this._eventbusService
            .observe(this._eventbusService.types.actionChosen)
            .pipe(take(1))
            .subscribe((reason) => {
              if (reason) {
                if (this.isCommunityPost) {
                  this.communityService.flagUnflagPost(comment, reason).then((result) => {
                    alert("A moderator has been notified of this flag. You may or may not be contacted for more information.");
                  });
                } else {
                  this.flagged.next(comment);
                }
                let args: any = {
                  userName: this.userService.user?.firstName + " " + this.userService.user?.lastName,
                  commentUserName: comment.author.name,
                  commentPhoto: comment.author.photo,
                  comment: comment.message,
                  reason: reason,
                  commentKey: comment.key,
                };
                if (this.video) {
                  args.videoTitle = this.video.title;
                  args.videoKey = this.video.key;
                }
                this.contentService.sendEmail(args, "notify_flagged_comment").subscribe(() => {});
                if (this.isCommunityPost && reason == "Copyright Violation") {
                  this._clipboardService.copy(this.env.mypresonusUrl + "community/post/" + comment.id);
                  alert(
                    "A direct link to this post has been copied to your clipboard. We are redirecting you to page with instructions to report this copyright infringement through the appropriate channels. Please include the link that was copied to your clipboard in your report."
                  );
                  window.location.href = "https://www.presonus.com/Claims-of-Copyright-Infringement-and-Related-Issues";
                }
                this.avoService.trackEvent().communityPostFlagged({
                  siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
                  channelName: this.getChannelName(this.comment.channels),
                  postType: comment.parentID === null ? "post" : "reply",
                  postId: comment.id,
                });
              }
            });
        }, 1000);
      }
    } else {
      this.appService.promptSimpleSignup(SimpleSignupComponent).then(
        () => {
          this.flag(comment);
        },
        (error) => {}
      );
    }
  }

  getChannelName(channels) {
    return channels === undefined ? ["general"] : channels;
  }

  unflag(comment) {
    if (this.isCommunityPost) {
      this.communityService.flagUnflagPost(comment);
    } else {
      this.unflagged.next(comment);
    }
  }

  upgrade() {
    this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  like(comment) {
    if (this.isCommunityPost) {
      if (this.user?.active_subscription || this.betaStringId) {
        if (comment.likes?.includes && comment.likes.includes(this.user?.id)) {
          comment.likes = comment.likes.filter((id) => id != this.user?.id);
          comment.iLike = false;
        } else {
          if (!comment.likes) comment.likes = [];
          comment.likes.push(this.userService.user?.id);
          comment.iLike = true;
        }
        this.likePost(comment, comment.iLike);
      } else {
        this.upgrade();
      }
    } else {
      if (this.user) {
        this.liked.next(comment);
      } else {
        this.appService.promptSimpleSignup(SimpleSignupComponent).then(
          () => {
            this.like(comment);
          },
          (error) => {}
        );
      }
    }
  }

  selectBetaPostStatus(event) {
    this.comment.beta_status = event;
    this.communityService.updatePost(this.comment.key, { beta_status: event }, `beta_community_posts`);
  }

  selectBetaPostType(event) {
    this.comment.beta_type = event;
    this.communityService.updatePost(this.comment.key, { beta_type: event }, `beta_community_posts`);
  }

  toggleMarkingAsReviewed() {
    this.markingAsReviewed = !this.markingAsReviewed;
    this.changeDetector.detectChanges();
  }

  markAsReviewed() {
    if ((this.betaReviewDetails.jira_ticket_number || this.betaReviewDetails.jira_ticket_link) && (!this.betaReviewDetails.jira_ticket_number || !this.betaReviewDetails.jira_ticket_link)) {
      alert("Please enter a Jira ticket number and link before marking this post as reviewed.");
      return;
    }
    this.betaReviewDetails.reviewed_by = {
      id: this.userService.user?.id,
      name: this.userService.user?.firstName + " " + this.userService.user?.lastName,
      photo: this.userService.user?.photoURL,
    };
    this.betaReviewDetails.reviewed_on = new Date();
    this.communityService.updatePost(
      this.comment.key,
      {
        beta_review_details: this.betaReviewDetails,
        reviewed: true,
      },
      `beta_community_posts`
    );
    this.markingAsReviewed = false;
    this.changeDetector.detectChanges();
  }

  createJiraTicket() {
    const modalRef = this.appService.showModal(CreateJiraTicketComponent, { size: "lg" });
    modalRef.componentInstance.data = {
      summary: this.comment.subject,
      description: this.helperService.htmlToMarkdown(this.comment.message),
      project: "SO",
    };
    if (this.comment.files) modalRef.componentInstance.attachments = this.comment.files;
    modalRef.componentInstance.linkToPost = "https://my.presonus.com/beta/betapost/" + this.comment.id + "?betaStringId=" + this.betaStringId;
    modalRef.componentInstance.completed.pipe(take(1)).subscribe((result) => {
      this.betaReviewDetails.jira_ticket_number = result.key;
      this.betaReviewDetails.jira_ticket_link = "https://fenderdigital.atlassian.net/browse/" + result.key;
      this.markAsReviewed();
    });
  }

  clearFlag(comment) {
    if (this.isCommunityPost) {
      this.communityService.clearFlag(comment.key);
    } else {
      this.flagCleared.next(comment);
    }
  }

  deletePost(post, admin?) {
    if (confirm("Are you sure you want to delete this post? This cannot be undone.")) {
      // this.deleteIndexSet.next(this.index)
      this.communityService.deletePost(post ? post.key : this.comment.key, admin, this.betaStringId ? `beta_community_posts` : "community_posts").then((result) => {});
    }
  }

  selectChannel(channel) {
    if (this.betaStringId) {
      this.channelSelected.next(channel);
    } else if (this.communityService.skillsMap[channel]) {
      this.channelSelected.next(channel);
    } else if (this.communityService.extraChannelsMap[channel] && this.communityService.extraChannelsMap[channel].active) {
      this.channelSelected.next(channel);
    }
  }

  likePost(comment?, isLiking?) {
    if (isLiking && this.userService.user?.id != comment.author.id) {
      let url = this.betaStringId ? `https://my.presonus.com/beta/betapost/${this.comment.id}?betaStringId=${this.betaStringId}` : `https://my.presonus.com/community/post/${this.comment.id}`;
      let notification: Notification = {
        title: this.user?.firstName + " " + this.user?.lastName + " liked your post in Community.",
        body: this.user?.firstName + " " + this.user?.lastName + " liked " + '"' + comment.message + '"',
        photo: this.userService.user?.photoURL,
        topics: ["community"],
        type: "community_post_liked",
        url: url,
        button: "View Post",
        payload: {
          primary_post_id: this.comment.id,
          post: {
            id: comment.id,
            message: this.helperService.htmlToText(comment.message),
            author: comment.author,
          },
          likedBy: {
            name: this.userService.user?.firstName + " " + this.userService.user?.lastName,
            id: this.userService.user?.id,
            photo: this.userService.user?.photoURL,
          },
        },
      };
      if (comment.parentID) {
        notification.payload.original_post = {
          id: this.comment.id,
          message: this.helperService.htmlToText(this.comment.message),
          author: this.comment.author,
        };
      }

      this.userService
        .sendNotification({
          notification: notification,
          user_ids: [comment.author.id],
          clients: ["rollup", "mobile", "email"],
        })
        .subscribe();
    }
    this.communityService.likeUnlikePost(comment || this.comment, isLiking, this.betaStringId ? `beta_community_posts` : "community_posts");
    if (isLiking) {
      this.avoService.trackEvent().communityPostLiked({
        siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        channelName: this.getChannelName(this.comment.channels),
        postType: comment.parentID === null ? "post" : "reply",
        postId: comment.id,
      });
    }
  }

  removeChannel(channel) {
    if (this.betaStringId && this.isBetaManager) {
      this.comment.channels = this.comment.channels.filter((item) => item != channel);
      this.communityService.updatePost(this.comment.key, { channels: this.comment.channels }, "beta_community_posts");
    }
  }

  addChannel(channel) {
    if (this.betaStringId && this.isBetaManager) {
      if (!this.comment.channels) this.comment.channels = [];
      if (this.comment.channels.includes(channel.string_id)) return;
      this.comment.channels.push(channel.string_id);
      this.communityService.updatePost(this.comment.key, { channels: this.comment.channels }, "beta_community_posts");
      this.shouldShowChannelSelector = false;
    }
  }

  pinPost() {
    this.communityService.pinUnpinPost(this.comment, this.betaStringId ? `beta_community_posts` : "community_posts");
    this.avoService.trackEvent().communityPostPinned({
      siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
      channelName: this.getChannelName(this.comment.channels),
      postType: this.comment.parentID === null ? "post" : "reply",
      postId: this.comment.id,
    });
  }

  editPost(comment?, isPinnedBetaPost?) {
    let editingComment = { ...(comment || this.comment) };
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg", backdrop: "static" });
    modalRef.componentInstance.comment = { ...editingComment };
    modalRef.componentInstance.isCommunityPost = true;
    modalRef.componentInstance.isBetaComment = this.betaStringId ? true : false;
    modalRef.result.then(
      (result) => {
        if (result) {
          // this.post.message = result.message;
          if (result.message) editingComment.message = result.message;
          editingComment.htmlMessage = this.appService.urlify(result.message);
          let editedData: any = {
            message: editingComment.message,
            edited: true,
          };
          if (result.subject) {
            editedData.subject = result.subject;
          }
          if (result.edits) {
            editedData.edits = result.edits.length ? result.edits : null;
          }
          if (result.mentions) {
            editedData.mentions = result.mentions.length ? result.mentions : null;
          }
          if (result.channels) {
            editedData.channels = result.channels.length ? result.channels : null;
          }
          if (result.files) {
            editedData.files = result.files;
          }

          if (result.articles) {
            editedData.articles = result.articles.length ? result.articles : null;
          }
          if (result.learn_content) {
            editedData.learn_content = result.learn_content.length ? result.learn_content : null;
          }
          if (comment.message != editedData.message) {
            comment.message = editedData.message;
            comment = this.communityService.buildHtmlComment(comment);
          }
          this.communityService.updatePost(editingComment.key || editingComment.id, editedData, this.betaStringId ? `beta_community_posts` : "community_posts").then(() => {
            if (isPinnedBetaPost) this._eventbusService.emit(this._eventbusService.types.betaAnnouncementUpdated, true);
          });
          this.postEdited.next({ post: editingComment, index: this.index });
        }
      },
      (error) => {}
    );
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  attachFile(droppedFile = false) {
    const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.isPostAttachment = true;
    if (droppedFile) modalRef.componentInstance.droppedFile = droppedFile;
    modalRef.componentInstance.uploadProgressChanged.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.uploadingFiles.forEach((item) => {
        if (item.id == result.file.id) item.progress = result.progress;
      });
    });
    modalRef.componentInstance.fileCreated.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (!this.uploadingFiles) this.uploadingFiles = [];
      this.uploadingFiles.push(result);
    });
    modalRef.componentInstance.fileUploadFailed.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.uploadingFiles = this.uploadingFiles.filter((item) => item.id != result.id);
    });
    modalRef.componentInstance.fileUploadFinished.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this._zone.run(() => {
        if (!this.uploadedAttachments) this.uploadedAttachments = [];
        result.url = this.communityService.buildFileUrl(result.user_id, result);
        this.uploadedAttachments.push(result);
        this.uploadingFiles = this.uploadingFiles.filter((item) => item.id != result.id);
      });
    });
  }

  removeAttachment(attachment?) {
    if (attachment) {
      this.uploadedAttachments = this.uploadedAttachments.filter((item) => item.id != attachment.id);
    } else {
      this.uploadedAttachments = undefined;
      this.uploadingFiles = undefined;
    }
  }

  isMobileScreen() {
    return window.innerWidth < 768;
  }

  ngOnDestroy() {}
}
