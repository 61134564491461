import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, ContentService, HelperService, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { FileUploader } from "ng2-file-upload";
import { Router } from "@angular/router";
import { AdminUploadFailedComponent } from "../admin-upload-failed/admin-upload-failed.component";

@Component({
  selector: "myp-admin-add-new-product",
  templateUrl: "admin-add-new-product.component.html",
})
export class AdminAddNewProductComponent extends BaseComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Output() productCreated: EventEmitter<any> = new EventEmitter();
  @Input() public data: any = {};
  public productSuccessArgs: any;
  public imageData: any = {};
  public contentPackageData: any = {};
  public loading: boolean = false;
  public productFileParser: FileUploader;
  public imageUploader: FileUploader;
  public contentPackageUploader: FileUploader;

  public hoveringFileDrops: any = {
    product: false,
    image: false,
    contentPackage: false,
  };

  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public uploadProgress: number;
  public createdContentPackageItem: any;
  public fileResponse: any;
  public replacingFile: boolean = false;
  public originalStorageLocation: string;
  public keySets: Array<any>;
  public selectedPath: string;
  public existingFolders: Array<string>;
  public chooseExistingFolder: boolean;
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public adminService: AdminService,
    public appService: AppService,
    public contentService: ContentService,
    public workspaceService: WorkspaceService,
    public helperService: HelperService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.data.categoryId) this.data.categoryId = "software";
    if (this.data.categoryId == "software") {
      this.adminService.getKeySets().subscribe((result) => {
        this.keySets = [];
        for (var i in result) {
          this.keySets.push({ id: result[i], key: i });
        }
        this.data.keySet = this.keySets[0].id;
      });
    }
    this.setupProductFileParser();
    this.setupImageUploader();
    this.setupContentPackageUploader();
    this.adminService.get("product/content-paths" + (this.data.categoryId == "extensions" ? "?extensions=true" : "")).subscribe((result: any) => {
      this.existingFolders = result;
    });
  }

  selectExistingFolder(args) {
    this.selectedPath = args;
  }

  public productFileData: any;
  public selectedProductFile: any;
  setupProductFileParser() {
    this.productFileParser = new FileUploader({
      isHTML5: true,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });

    this.productFileParser.onAfterAddingFile = (f) => {
      // Only read the file if it has a .product extension
      if (f.file.name.endsWith(".product")) {
        this.selectedProductFile = f;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          try {
            const fileContent = JSON.parse(fileReader.result as string);
            this.productFileData = fileContent;
            this.data = {
              ...this.data,
              ...this.productFileData,
              stringId: this.productFileData.identifier,
            };
          } catch (error) {
            console.error("Failed to parse .product file", error);
          }
        };

        fileReader.onerror = (error) => {
          console.error("Error reading file", error);
        };

        // Read the file as text
        fileReader.readAsText(f._file);
      } else {
        alert('Please select a ".product" file');
        this.productFileParser.clearQueue();
      }
    };
  }

  clearProductFile() {
    this.productFileParser.clearQueue();
    this.data = {
      categoryId: this.data.categoryId,
    };
    this.productFileData = undefined;
    this.selectedProductFile = undefined;
  }

  public selectedImageFile: any;
  public displayImage: any;
  setupImageUploader() {
    this.imageUploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      if (file.file.type.startsWith("image/")) {
        this.selectedImageFile = file;
        this.contentService.uploadSession.filesMap[file.file.name] = { file };
        const reader = new FileReader();

        reader.onload = () => {
          this.displayImage = reader.result; // Set the data URL
        };

        reader.onerror = (error) => {
          console.error("Error reading file", error);
        };

        reader.readAsDataURL(file._file); // Read the file as a data URL
      } else {
        alert("Please select an image file");
        this.imageUploader.clearQueue();
      }
    };
    this.imageUploader.onProgressAll = (progress: any) => {
      if (this.contentService.uploadSession.filesMap[this.selectedImageFile.file.name]) {
        this.contentService.uploadSession.filesMap[this.selectedImageFile.file.name].progress = progress;
      }
    };

    this.imageUploader.onErrorItem = (error: any) => {
      this.appService.showModal(AdminUploadFailedComponent, { size: "lg" }).componentInstance.data = this.contentService.uploadSession.filesMap[error.file.name];
      delete this.contentService.uploadSession.filesMap[error.file.name];
    };
  }
  removeImage() {
    this.imageUploader.clearQueue();
    this.displayImage = undefined;
    this.selectedImageFile = undefined;
    this.hoveringFileDrops.image = false;
  }

  public selectedContentPackageFile: any;
  setupContentPackageUploader() {
    this.contentPackageUploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    this.contentPackageUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.selectedContentPackageFile = file;
      this.contentService.uploadSession.filesMap[file.file.name] = { file };
    };
    this.contentPackageUploader.onProgressAll = (progress: any) => {
      if (this.contentService.uploadSession.filesMap[this.selectedContentPackageFile.file.name]) {
        this.contentService.uploadSession.filesMap[this.selectedContentPackageFile.file.name].progress = progress;
      }
    };

    this.contentPackageUploader.onErrorItem = (error: any) => {
      this.appService.showModal(AdminUploadFailedComponent, { size: "lg" }).componentInstance.data = this.contentService.uploadSession.filesMap[error.file.name];
      delete this.contentService.uploadSession.filesMap[error.file.name];
    };
  }

  removeContentPackage() {
    this.contentPackageUploader.clearQueue();
    this.selectedContentPackageFile = undefined;

    this.hoveringFileDrops.contentPackage = false;
  }

  clearFile() {
    this.productFileParser.clearQueue();
    this.uploadingComplete = false;
    this.fileResponse = undefined;
    this.hoveringFileDrops.product = false;
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    if (this.data.releaseDate) modalInstance.componentInstance.initWithDate = new Date(this.data.releaseDate);
    modalInstance.componentInstance.dateSelected.subscribe((date) => (this.data.releaseDate = date));
  }

  fileOverBase(e, type) {
    this.hoveringFileDrops[type] = e;
  }

  submit() {
    if ((this.selectedContentPackageFile || this.selectedImageFile) && !this.selectedPath) {
      alert("Please enter a path to upload the image and extension file to.");
      return;
    }
    let args: any = {
      categoryId: this.data.categoryId,
      title: this.data.title,
      stringId: this.data.stringId,
      description: this.data.description,
      version: this.data.version,
      keySet: this.data.keySet,
      featureSet: this.data.featureSet,
      policy: this.data.policy,
      manifest: this.data.manifest,
      shortDescription: this.data.shortDescription,
    };

    if (args.title && args.stringId && args.version) {
      this.loading = true;
      this.adminService.addUpdateProduct(args).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.productCreated.next(res);
          this.router.navigate(["/products/detail", res.productId]);
          this.productSuccessArgs = res;
          if (this.imageUploader.queue.length) {
            this.imageData.folder = `pictures/${this.selectedPath}`;
            this.imageData.title = "icon";
            this.imageData.DTYPE = "ContentPackage";
            this.imageData.product_id = this.productSuccessArgs.productId;
            this.imageData.stringId = "bigicon";
            this.imageData.downloadSection = "no_download";
            this.imageData.fileName = this.selectedImageFile.file.name;
            this.imageData.type = "content";
            this.imageData.contentLength = this.selectedImageFile.file.size;
            this.contentService.getPresignedUploadURL(this.imageData).subscribe((result) => {
              this.contentService.uploadSession.filesMap[result.file.fileName].paeEntity = result.file;
              this.imageUploader.queue.forEach((file) => {
                let uploadHeaders = [];
                uploadHeaders.push({ name: "Content-Type", value: this.selectedImageFile.file.type });
                uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + this.imageData.fileName + '"' }); // this is breaking upload for some filenames that are non ASCII standard
                file.headers = uploadHeaders;
                file.url = result.url;
                this.imageUploader.uploadItem(file);
              });
            });
          }
          if (this.contentPackageUploader.queue.length) {
            this.contentPackageData.folder = this.selectedPath;
            this.contentPackageData.title = this.data.title;
            this.contentPackageData.DTYPE = "ContentPackage";
            this.contentPackageData.product_id = this.productSuccessArgs.productId;
            this.contentPackageData.stringId = this.data.stringId;
            this.contentPackageData.downloadSection = "no_download";
            this.contentPackageData.fileName = this.selectedContentPackageFile.file.name;
            this.contentPackageData.type = "content";
            this.contentPackageData.contentLength = this.selectedContentPackageFile.file.size;
            this.contentService.getPresignedUploadURL(this.contentPackageData).subscribe((result) => {
              this.contentService.uploadSession.filesMap[result.file.fileName].paeEntity = result.file;
              this.contentPackageUploader.queue.forEach((file) => {
                let uploadHeaders = [];
                uploadHeaders.push({ name: "Content-Type", value: undefined });
                uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + this.contentPackageData.fileName + '"' }); // this is breaking upload for some filenames that are non ASCII standard
                file.headers = uploadHeaders;
                file.url = result.url;
                this.contentPackageUploader.uploadItem(file);
              });
            });
          }
          if (!this.contentPackageUploader?.queue?.length && !this.imageUploader?.queue?.length) {
            this.completed.next(this.productSuccessArgs);
            this.activeModal.close();
          }
        },
        error: (err) => {
          this.appService.alertError(err);
          this.activeModal.close();
        },
      });
    } else {
      alert("Please fill out all required fields.");
    }
  }
}
