<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.upgrade.title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="upgradeSoftwareLoader"></myp-inline-loader>

  <div class="form-group m-b-20" *ngIf="!loading">
    <span>{{ isAdmin ? "Enter the upgrade key." : "If you have an upgrade key, enter it below to upgrade this license." }}</span>
    <br />
    <br />
    <input id="productkey" class="form-control mono" (keydown)="keyPressed($event)" guide="false" [maskito]="{ mask: mask }" [(ngModel)]="productKey" />
    <br />
    <button type="button" class="btn btn-primary" (click)="performUpgrade()">{{ "product_detail.upgrade.upgrade" | translate }}</button>
  </div>
  <ng-container *ngIf="!isAdmin">
    <hr class="m-t-20 m-b-20" />
    <div>
      <span>Otherwise, you can purchase an upgrade by clicking below.</span>
      <br />
      <br />
      <button type="button" class="btn btn-primary" (click)="goToCheckout()">
        Go To Checkout &nbsp;
        <fa-icon icon="spinner" *ngIf="loadingOptions" animation="spin"></fa-icon>
      </button>
    </div>
  </ng-container>
</div>
