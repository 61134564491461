import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, Subject, catchError, concatMap, firstValueFrom, from, of, switchMap, take, throwError } from "rxjs";
import { environment, UserService } from "@mypxplat/xplat/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable()
export class FirebaseService {
  public fbUser: any;
  private _logginFBUserIn: boolean = false;
  public firbeaseUserLoggedIn$: Subject<boolean> = new Subject();

  constructor(
    public auth: AngularFireAuth,
    public userService: UserService,
    public db: AngularFirestore
  ) {
    this.auth.onAuthStateChanged((credential) => {
      this.fbUser = credential;
    });
  }

  async handleFirestorePromise<T>(request: () => Promise<T>): Promise<T> {
    try {
      return await request();
    } catch (error) {
      const normalizedError = error instanceof Error ? error : new Error(String(error)); // Ensure error is an instance of Error
      if (normalizedError["code"] === "permission-denied") {
        const user = await firstValueFrom(this.auth.user.pipe(take(1))); // Replace toPromise with firstValueFrom
        if (!user) {
          await this.loginFirebaseUser();
          return await request(); // Retry the request after login
        }
      }
      throw normalizedError; // Rethrow the normalized error
    }
  }

  public checkForSplash(productStringId?: string, splashId?: string) {
    return new Promise((resolve, reject) => {
      if (splashId) {
        this.handleFirestorePromise(() => this.db.collection("product_splashes").doc(splashId).ref.get()).then((results) => {
          if (results.exists) {
            let data: any = results.data();
            data.string_id = results.id;
            data.id = results.id;
            resolve(data);
          } else {
            resolve(false);
          }
        });
      } else if (productStringId) {
        this.handleFirestorePromise(() => this.db.collection("product_splashes").ref.where("string_ids", "array-contains", productStringId).get()).then((results) => {
          let allResults = results.docs.map((result) => {
            let data: any = result.data() || {};
            return { ...data, id: result.id, string_id: result.id };
          });
          let subOnlyResults = allResults.filter((result) => result.id.indexOf("_sphere") > -1);
          let devResults = allResults.filter((result) => result.id.indexOf("_dev") > -1);

          if (devResults.length && !environment.production) {
            resolve(devResults[0]);
          } else if (this.userService.user.active_subscription && subOnlyResults.length > 0) {
            resolve(subOnlyResults[0]);
          } else if (allResults.length > 0) {
            resolve(allResults[0]);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  // public checkForSplash(id: any, checkDev: boolean = true) {
  //   return new Promise((resolve, reject) => {
  //     this.handleFirestorePromise(() =>
  //       this.db
  //         .collection("product_splashes")
  //         .doc(id + (!environment.production && checkDev ? "_dev" : ""))
  //         .ref.get()
  //     )
  //       .then((regularResult) => {
  //         if (this.userService.user?.active_subscription) {
  //           this.db
  //             .collection("product_splashes")
  //             .doc(id + "_sphere" + (!environment.production && checkDev ? "_dev" : ""))
  //             .ref.get()
  //             .then((sphereResult) => {
  //               if (sphereResult.exists) {
  //                 let sphereData: any = sphereResult.data();
  //                 sphereData.string_id = sphereResult.id;
  //                 resolve(sphereData);
  //               } else if (regularResult.exists) {
  //                 let regularData: any = regularResult.data();
  //                 regularData.string_id = regularResult.id;
  //                 resolve(regularData);
  //               } else {
  //                 resolve(false);
  //               }
  //             })
  //             .catch(() => {});
  //         } else {
  //           if (regularResult.exists) {
  //             let regularData: any = regularResult.data();
  //             regularData.string_id = regularResult.id;
  //             resolve(regularData);
  //           } else {
  //             resolve(false);
  //           }
  //         }
  //       })
  //       .catch(() => {});
  //   });
  // }

  handleFirestoreObservable<T>(request: () => Observable<T>): Observable<T> {
    return request().pipe(
      catchError((error: any) => {
        if (error.code === "permission-denied") {
          return from(firstValueFrom(this.auth.user.pipe(take(1)))).pipe(
            switchMap(async (user) => {
              if (!user) {
                await this.loginFirebaseUser();
                return request();
              }
            }),
            catchError(() => throwError(() => new Error("Permission denied")))
          );
        }
        return throwError(() => new Error(error || "Unknown error")); // Wrap all errors properly
      }),
      switchMap((result) => (result instanceof Observable ? result : of(result)))
    );
  }

  private _currentPromise: Promise<any> | null = null;
  public loginFirebaseUser() {
    if (this._currentPromise) {
      return this._currentPromise;
    }
    this._currentPromise = new Promise((resolve, reject) => {
      if (!this._logginFBUserIn) {
        this._logginFBUserIn = true;
        if (this.fbUser && this.fbUser.uid == this.userService.user.id) {
          this._logginFBUserIn = false;
          this.firbeaseUserLoggedIn$.next(true);
          resolve(true);
        } else {
          this.auth.user.pipe(take(1)).subscribe({
            next: (credential) => {
              this.fbUser = credential;
              if (!this.fbUser || this.fbUser.uid != this.userService.user.id) {
                if (!this.userService.user || !this.userService.user.firebase_token) {
                  this.userService.getUserDetails(false).subscribe((userDetails) => {
                    this.auth
                      .signInWithCustomToken(userDetails.firebase_token)
                      .then((result) => {
                        this.firbeaseUserLoggedIn$.next(true);
                        this._logginFBUserIn = false;
                        resolve(true);
                      })
                      .catch((error) => {
                        this._logginFBUserIn = false;
                        this._currentPromise = null;
                        reject(new Error(error?.message || "An error occurred while logging in."));
                      });
                  });
                } else {
                  this.auth
                    .signInWithCustomToken(this.userService.user.firebase_token)
                    .then((result) => {
                      this.firbeaseUserLoggedIn$.next(true);
                      this._logginFBUserIn = false;
                      resolve(true);
                    })
                    .catch((error) => {
                      this._logginFBUserIn = false;
                      reject(new Error(error?.message || "An error occurred while logging in."));
                    });
                }
              } else {
                this.firbeaseUserLoggedIn$.next(true);
                this._logginFBUserIn = false;
                resolve(true);
              }
            },
            error: (err) => {
              reject(new Error(err?.message || "An error occurred while logging in."));
            },
          });
        }
      } else {
        reject(new Error("User is already logging in."));
      }
    });

    this._currentPromise.then(
      () => {
        this._currentPromise = null;
      },
      () => {
        this._currentPromise = null;
      }
    );

    return this._currentPromise;
  }
}
