import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent, Product, ProductService, ProductSnapshot, UserService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-beta-details",
  templateUrl: "beta-details.component.html",
})
export class BetaDetailsComponent extends BaseComponent {
  @Input() betaProgram: any;
  public redeemingProduct: boolean = false;
  public loading: boolean;
  public productDetails: any = {};
  public isBetaManager: boolean;
  constructor(
    userService: UserService,
    public productService: ProductService
  ) {
    super(userService);
    this.isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
  }

  getProductDetails(product) {
    if (!this.productDetails[product.id]) {
      product.loading = true;
      this.productService.getProductSnapshot(product.id, undefined, this.betaProgram.id).subscribe((snapshot: ProductSnapshot) => {
        product.expanded = true;
        this.productDetails[snapshot.product.id] = snapshot;
        product.loading = false;
      });
    } else {
      product.expanded = !product.expanded;
    }
  }

  claimBetaProduct(claimBetaProduct) {
    this.redeemingProduct = true;
    this.productService.redeemBetaProduct(this.betaProgram.id).subscribe((result) => {
      this.productService.getProducts(true).subscribe((result) => {
        this.redeemingProduct = false;
      });
    });
  }
}
