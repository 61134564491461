import { Component, OnInit, NgZone, OnDestroy, ViewChild, ElementRef, HostListener } from "@angular/core";
import {
  Download,
  BundledDownloads,
  Downloads,
  Product,
  ProductDetail,
  GetOemKeyResponse,
  ContentService,
  CheckoutService,
  ProductSnapshot,
  ProductListItem,
  ProductLicenseItem,
  ProductDownload,
} from "@mypxplat/xplat/core";
import { BaseComponent, UserService, ProductService, HelperService, WindowService, GAService, environment, EventBusService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router, NavigationEnd, NavigationExtras } from "@angular/router";
import * as moment from "moment";
import {
  ActivateOfflineComponent,
  TransferLicenseComponent,
  UpgradeSoftwareComponent,
  ManageActivationsComponent,
  RegisterProductComponent,
  FirmwareInstructionsComponent,
  IlokComponent,
  RedeemHardwareOfferComponent,
  RegisterSuccessSplashComponent,
  StudiooneGracePeriodComponent,
  UpgradeSoftwareStripeComponent,
  TransferProductComponent,
} from "../modals";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AppService, AvoService, BetaService, FirebaseService } from "@mypxplat/xplat/web/core";
import natsort from "natsort";
import { ClipboardService } from "ngx-clipboard";
import { filter, take, takeUntil } from "rxjs/operators";
import { ProductDownloadsComponent } from "../product-downloads/product-downloads.component";
import { format } from "date-fns";
import { EventEmitter } from "stream";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "myp-shared-product-detail",
  templateUrl: "product-detail.component.html",
})
export class ProductDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("productDownloads") productDownloads: ProductDownloadsComponent;
  @ViewChild("prodDetailTitle") prodDetailTitle: ElementRef;
  @ViewChild("prodDetailHead") prodDetailHead: ElementRef;
  @ViewChild("prodDetailSidebar") prodDetailSidebar: ElementRef;
  public snapshot: ProductSnapshot;
  public listProduct: ProductListItem;
  public product: any;
  public details: ProductDetail;
  public group: Array<Product>;
  public loading: boolean = false;
  public loadingLicenses: boolean = false;
  public selectedVideo: any = false;
  public selectedLicense: any;
  public productImage: string;
  public hasThirdPartyKeys: boolean = false;
  public children: Array<Product>;
  public bundledProducts: Array<any>;
  public optionCards: Array<any>;
  public downloads: ProductSnapshot["downloads"];
  public downloadSections: Array<any>;
  public displayedDownloadSection: any;
  public resources: any;
  public resourcesSections: Array<any>;
  public displayedResourcesSection: string;
  public bundledDownloads: ProductSnapshot["bundledDownloads"];
  public installFile: any;
  public s1OrNotion: boolean;
  public isS15: boolean;
  public isS16: boolean;
  public isS17: boolean;
  public showAllSystems: boolean = false;
  public myOs: string;
  public showKeyActions: boolean = true;
  public computerName: string;
  public routerEvents: any;
  public installMethods: Array<string>;
  public shouldShowSphereRedeem: boolean = false;
  public features: string;
  public featuresCollapsed: boolean = false;
  public samplesCollapsed: boolean = false;
  public downloadsCollapsed: boolean = false;
  public bundledProductsCollapsed: boolean = false;
  public resourcesCollapsed: boolean = false;
  public samplePrefix = "https://pae-web.presonusmusic.com/uploads/products/";
  public env = environment;
  public isNotionMobile: boolean;
  public viewingOffers: boolean = false;
  public hashFragment: string;
  public couponParam: string;
  public subscriptionOnly: boolean;
  public shouldBeSticky: boolean = false;
  public licenses: Array<ProductLicenseItem>;
  public latestFirmware: ProductDownload;
  public gotLicenses$: BehaviorSubject<any> = new BehaviorSubject(false);
  public hasExtrasOrOffers: boolean = false;
  public canRedeemStudioOneSeven: boolean = false;
  constructor(
    userService: UserService,
    private _route: ActivatedRoute,
    public eventBusService: EventBusService,
    public productService: ProductService,
    public helperService: HelperService,
    public appService: AppService,
    private _zone: NgZone,
    public win: WindowService,
    private router: Router,
    public ga: GAService,
    private _translate: TranslateService,
    private _spin: NgxSpinnerService,
    public db: AngularFirestore,
    private _clipboardService: ClipboardService,
    public contentService: ContentService,
    public betaService: BetaService,
    public avoService: AvoService,
    public fbService: FirebaseService,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.myOs = this.win.navigator.platform.indexOf("Mac") > -1 ? "mac" : "win";
    let id = this._route.snapshot.params["id"];
    const urlTree = this.router.parseUrl(this.router.url);
    this.hashFragment = urlTree.fragment;
    this.initView(id);
    this.routerEvents = this.router.events.subscribe((result) => {
      // this allows you to "navigate" to another product detail from this page
      if (result instanceof NavigationEnd) {
        this.initView(this._route.snapshot.params["id"]);
        this.measureUI();
      }
    });

    this.appService.sphereAppContentScrolled$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.shouldBeSticky = result > 66;
    });
  }

  ngOnDestroy() {
    if (this.routerEvents && this.routerEvents.unsubscribe) this.routerEvents.unsubscribe();
    super.ngOnDestroy();
  }

  initView(id) {
    this.bundledProducts = [];
    this.loading = true;
    this.licenses = undefined;
    this.hasExtrasOrOffers = false;
    this.productService.products$
      .pipe(
        filter((result) => !!result),
        take(1)
      )
      .subscribe((result) => {
        this.listProduct = this.productService.listProductDataMap[id];
        if (this.listProduct) {
          this.userService.subDetails$
            .pipe(
              filter((item) => !!item),
              take(1)
            )
            .subscribe((result) => {
              if (this.listProduct.stringId == "studioapp7.pro" && this.userService.subDetails?.active && this.userService.subDetails?.subscription?.Asset_id) {
                if (!this.listProduct.assetIds) this.listProduct.assetIds = [];
                this.listProduct.assetIds.push(this.userService.subDetails.subscription.Asset_id);
              }
              if (this.listProduct.assetIds) {
                this.loadingLicenses = true;
                this.productService.getDetailForAssetIds(id, this.listProduct.assetIds).subscribe((result) => {
                  this.loadingLicenses = false;
                  this.licenses = result;
                  this.gotLicenses$.next(true);
                  this.optionCards = [];

                  this.licenses.forEach((item) => {
                    if (item.bundledProducts && item.bundledProducts.length) {
                      item.bundledProducts.forEach((bundleItem: any) => {
                        if (bundleItem.title.toLowerCase().indexOf("card") > -1) {
                          bundleItem.parentSerial = item.hardwareSerialNumber;
                          bundleItem.parentLicenseId = item.licenseId;
                          this.optionCards.push(bundleItem);
                        } else {
                          if (!this.productService.productsByStringIDs[bundleItem.stringId] && bundleItem.categoryId != "hardware") bundleItem.redeemable = true;
                        }
                      });
                      this.bundledProducts = item.bundledProducts.filter((item) => item.title.toLowerCase().indexOf("card") == -1);
                    }
                    if (item.oemItems && item.oemItems.length) this.hasThirdPartyKeys = true;
                    if (item.activations && item.activations.length) item.activations[0].computerName = encodeURIComponent(item.activations[0].comment);
                    if (!item.upgraded) this.shouldShowSphereRedeem = true;
                  });
                });
              } else {
                this.gotLicenses$.next(true);
                this.licenses = [];
              }
            });
          this.productService.getProductSnapshot(id, this.listProduct.fallbackVersion).subscribe({
            next: (result: ProductSnapshot) => {
              this.snapshot = result;
              if (this._route.snapshot.queryParams["showSplash"]) {
                this.fbService.checkForSplash(this.snapshot.product.stringId).then((result) => {
                  if (result) this.showSplash(result);
                });
                this.router.navigate([], {
                  queryParams: { showSplash: null },
                  queryParamsHandling: "merge",
                });
              }
              this.loading = false;
              if (this.snapshot.firmware?.length) {
                this.snapshot.firmware.sort((a, b) => (parseInt(a.version.replace(/\./g, "").slice(0, 3)) > parseInt(b.version.replace(/\./g, "").slice(0, 3)) ? -1 : 1));
                this.latestFirmware = this.snapshot.firmware[0];
              }
              this.product = this.productService.getProduct(id);
              if (this.product) {
                this.productImage = this.snapshot.product.imageUrl;
                this.s1OrNotion =
                  this.snapshot.product.categoryId == "software" && (this.snapshot.product.stringId.indexOf("studioapp") > -1 || this.snapshot.product.licenseScheme == 1) ? true : false;
                this.isS15 = this.snapshot.product.erpId && this.snapshot.product.erpId.indexOf("STUDIOONE5") > -1;
                this.isS16 = this.snapshot.product.erpId && this.snapshot.product.erpId.indexOf("STUDIOONE6") > -1;
                this.isS17 = this.snapshot.product.erpId && this.snapshot.product.erpId.indexOf("STUDIOONEPRO7") > -1;
                this.isNotionMobile = this.snapshot.product.stringId && this.snapshot.product.stringId.indexOf("notionmobile3") > -1;
                // this.setup();
              } else {
                this.productService.getProducts().subscribe((data: any) => {
                  this.product = this.productService.getProduct(id);
                  // this.setup();
                });
              }
              this.productImage = this.snapshot.product.imageUrl;
              this.s1OrNotion = this.snapshot.product.categoryId == "software" && (this.snapshot.product.stringId.indexOf("studioapp") > -1 || this.snapshot.product.licenseScheme == 1) ? true : false;
              if (this.snapshot.product.installMethod && this.snapshot.product.installMethod != "none") {
                this.installMethods = this.snapshot.product.installMethod.split("-");
                this.installMethods.reverse();
              }
              this.bundledDownloads = this.snapshot.bundledDownloads;

              this.gotLicenses$
                .pipe(
                  filter((item) => !!item),
                  take(1)
                )
                .subscribe((result) => {
                  if (result) {
                    this.hasExtrasOrOffers = this.checkExtrasOrOffers();
                    this.handleDownloads();
                  }
                });

              if (this.snapshot.product.partnerName == "third_party" && !this.snapshot.product.partnerKey) this.showKeyActions = false;
              this.resources = {};
              // if (this.snapshot && this.snapshot.accessories && this.snapshot.accessories.length) this.resources.accessories = this.snapshot.accessories;
              this.resourcesSections = [];
              if (this.snapshot.videos && this.snapshot.videos.length) {
                this.resourcesSections.push({
                  value: "videos",
                  displayed: false,
                });
              }
              for (let i in this.resources) {
                this.resourcesSections.push({
                  value: i,
                  displayed: false,
                });
              }
              this.displayResourceSection(this.resourcesSections[0]);
              this.loading = false;
              this.win.setTimeout(() => {
                this.measureUI();
              }, 10);

              if (this.hashFragment && this.hashFragment.indexOf("downloads_") > -1) {
                this.win.setTimeout(() => {
                  let inputContainerEl = document.getElementById("product-downloads");
                  if (inputContainerEl && inputContainerEl.scrollIntoView) {
                    inputContainerEl.scrollIntoView({
                      behavior: "smooth",
                    });
                    if (this.productDownloads && this.productDownloads.displayDownloadSection) this.productDownloads.displayDownloadSection(false, this.hashFragment.split("downloads_")[1]);
                  }
                }, 800);
              }

              this.avoService.avoInitted$.pipe(filter((result) => !!result)).subscribe((result) => {
                this.avoService.trackEvent().productViewed({
                  siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
                  name: this.snapshot.product.title,
                  locale: (this.user?.language_code || "en") + "-" + (this.user?.country || "US"),
                  productId: this.snapshot.product.id.toString(),
                  category: this.snapshot.product.categoryId,
                  sku: this.snapshot.product.id.toString(),
                });
              });

              this.fbService.handleFirestorePromise(() => {
                return this.db.doc("user_metadata/" + this.user.id).set(
                  {
                    products_seen: {
                      [this.snapshot.product.id]: true,
                    },
                  },
                  { merge: true }
                );
              });
              if (!this.userService.userMetadata) {
                this.userService.userMetadata = {
                  products_seen: {},
                };
              } else if (!this.userService.userMetadata.products_seen) {
                this.userService.userMetadata.products_seen = {};
              }
              this.userService.userMetadata.products_seen[this.snapshot.product.id] = true;

              if (this._route.snapshot.queryParams["upgrade"]) {
                let upgradeProduct: boolean = JSON.parse(this._route.snapshot.queryParams["upgrade"]);
                if (upgradeProduct) {
                  if (this._route.snapshot.queryParams["coupon"]) {
                    this.couponParam = this._route.snapshot.queryParams["coupon"];
                  }
                  this.showUpgrade(this.product);
                }
              }
              window.scrollTo(0, 0);
            },
            error: (error) => {
              this.loading = false;
              this.appService.alertError(error);
              this.helperService.goBack();
            },
          });
        } else {
          this.goBack();
        }
      });
  }

  handleDownloads() {
    this.downloads = this.snapshot.downloads;
    if (this.snapshot.firmware?.length) {
      this.downloads.firmware = this.snapshot.firmware;
      this.downloads.firmware.forEach((item) => {
        item.licenseId = this.licenses[0].licenseId;
      });
    }
    this.downloadSections = [];
    if (this.isNotionMobile) {
      if (!this.downloads.owners_manuals) this.downloads.owners_manuals = [];
      this.downloads.owners_manuals.unshift({
        url: "https://notionmobilemanual.presonus.com",
        title: "Notion Mobile Online Manual",
        tileTitle: "Notion Mobile",
        type: "link",
      });
    }

    for (let i in this.downloads) {
      if (i != "installers") {
        this.downloadSections.push({
          value: i,
          displayed: false,
        });
      }
    }
    if ((this.isS15 || this.isS16 || this.isS17) && this.downloads.owners_manuals) {
      if (!this.downloads.owners_manuals.length) this.downloads.owners_manuals = [];
      this.downloads.owners_manuals.unshift({
        url: "https://s1manual.presonus.com",
        title: "Studio One Online Manual",
        tileTitle: "Studio One",
        type: "link",
      });
    }
    this.displayDownloadSection(this.downloadSections[0]);
  }

  playSample(smp, i?) {
    let playlistFiles = [];
    this.snapshot.samples.forEach((item) => {
      playlistFiles.push({
        url: this.samplePrefix + item.file_products_audio,
        filename: item.title_products_audio,
        id: item.idproducts_audio,
      });
    });
    this.eventBusService.emit(this.eventBusService.types.playAudio, {
      url: this.samplePrefix + smp.file_products_audio,
      filename: smp.title_products_audio,
      id: smp.idproducts_audio,
      playlist: playlistFiles,
    });
  }

  imageClick() {
    this.fbService.checkForSplash(this.snapshot.product.stringId).then((result) => {
      if (result) this.showSplash(result);
    });
  }

  goBack() {
    this.helperService.goBack();
  }

  checkExtrasOrOffers(): boolean {
    // Check for any licenses with offers
    if (this.licenses) {
      if (this.licenses.some((license) => license.offers && license.offers.length > 0)) {
        return true;
      }
    }
    if (this.snapshot) {
      // Check for redeemable products based on product IDs
      if (this.snapshot.product.stringId === "studioapp4.pro") {
        if (!this.productService.productsByStringIDs["presonus.ampire"] || !this.productService.productsByStringIDs["presonus.fatchannel"]) {
          return true;
        }
      }
    }

    // Any other conditions that might show content can be added here

    return false; // Return false if none of the conditions are met
  }

  buy() {
    this.appService.goToCheckout("/checkout?checkoutProductType=Perpetual");
  }

  showSplash(splash) {
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.productId = this.snapshot.product.id;
    modalRef.componentInstance.productName = this.snapshot.product.title;
  }

  copyLink(video, event) {
    this._clipboardService.copy(environment.mypresonusUrl + "content/productvideos/" + this.snapshot.product.stringId + "/" + video.service_id_videos);
    event.stopPropagation();
  }

  showVideo(video) {
    this.eventBusService.emit(this.eventBusService.types.playVideo, {
      id: video.service_id_videos,
      title: video.title_videos,
      type: "youtube",
      thumb: video.thumb_videos,
    });
  }

  videoClosed() {
    this.selectedVideo = undefined;
  }

  removeHardware(item) {
    this._translate.get("product_detail.confirm_remove_hardware").subscribe((string) => {
      this.win.confirm(string + "\n Serial No: " + item.hardwareSerialNumber).then(
        (_) => {
          this._spin.show();
          this.productService.deregisterHardware(item.licenseId, true).subscribe((result) => {
            this._spin.hide();
            this.router.navigate(["products/hardware"], <NavigationExtras>{
              skipLocationChange: true,
            });
          });
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  showActivateOffline(license) {
    const modalRef = this.appService.showModal(ActivateOfflineComponent, { size: "sm" });
    modalRef.componentInstance.license = license;
    modalRef.componentInstance.activationCompleted.subscribe((result) => {
      if (result) {
        this.initView(this.listProduct.id);
      }
    });
  }

  showS17Redeem() {
    const modalRef = this.appService.showModal(StudiooneGracePeriodComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.asset = this.productService.s16ProductToUpgradeTo7;
  }

  downloadHub() {
    if (this.productService.productsByStringIDs["presonushub"]) {
      this.productService.getProductSnapshot(this.productService.productsByStringIDs["presonushub"].id).subscribe((snapshot: ProductSnapshot) => {
        if (this.appService.os == "mac") {
          window.open(snapshot.downloads.installers.mac[0].url);
        } else {
          if (snapshot.downloads.installers.win64) {
            window.open(snapshot.downloads.installers.win64[0].url);
          } else {
            window.open(snapshot.downloads.installers.win[0].url);
          }
        }
      });
    } else {
      this.productService.redeemProduct(false, "hub_id").subscribe((result) => {
        if (result.product) {
          if (this.appService.os == "mac") {
            this._getTokenizedInstaller(result.product.product.product.downloads.installers.mac[0]);
          } else {
            if (result.product.product.product.downloads.installers.win64) {
              this._getTokenizedInstaller(result.product.product.product.downloads.installers.win64[0]);
            } else {
              this._getTokenizedInstaller(result.product.product.product.downloads.installers.win[0]);
            }
          }
          this.productService.getProducts(true).subscribe();
        }
      });
    }
  }

  private _getTokenizedInstaller(dl) {
    if (dl.tokenized) {
      this.productService
        .getTokenizedUrl(
          dl.folder,
          dl.fileName,
          this.userService.appId == "mypresonus" ? "mypresonus" : "studio_one_plus",
          dl.title,
          dl.id.toString(),
          dl.fileName,
          parseInt(dl.contentLength),
          dl.version || dl.version_number
        )
        .subscribe({
          next: (result: any) => {
            window.open(result.url);
          },
          error: (error) => {
            this.appService.alertError(error);
          },
        });
    } else {
      window.open(dl.url);
    }
  }

  showTransferLicense(item) {
    this.appService.goToCheckout(`/checkout?checkoutProductType=Transfer&assetId=${item.licenseId}&productId=${this.snapshot.product.id}`);
  }

  showUpgrade(item) {
    const modalRef = this.appService.showModal(UpgradeSoftwareComponent, {
      size: "md",
      backdrop: "static",
    });
    modalRef.componentInstance.upgradingProduct = {
      ...this.snapshot.product,
      license: item,
    };
  }

  showManageActivations(item) {
    if (item.activations?.length && this.snapshot.product.licenseScheme != 1) {
      const modalRef = this.appService.showModal(ManageActivationsComponent, { size: "lg" });
      modalRef.componentInstance.product = this.snapshot.product;
      modalRef.componentInstance.license = item;
      modalRef.componentInstance.activationDeleted.subscribe((result) => {
        if (result.activation_id) {
          this.initView(this.snapshot.product.id);
          // this.update();
        }
      });
    }
  }

  openOffers(item) {
    const modalRef = this.appService.showModal(RedeemHardwareOfferComponent, { size: "lg" });
    modalRef.componentInstance.parentProduct = this.snapshot.product;
    modalRef.componentInstance.offers = item.offers;
    modalRef.componentInstance.license = item;
    modalRef.componentInstance.redeemed.subscribe((result) => {
      this.initView(this._route.snapshot.params["id"]);
    });
  }

  displayDownloadSection(section) {
    if (this.downloadSections.length) {
      this.downloadSections.forEach((item) => {
        item.displayed = false;
      });
      section.displayed = true;
      this.displayedDownloadSection = section;
    }
  }

  displayResourceSection(section) {
    if (this.resourcesSections.length) {
      this.resourcesSections.forEach((item) => {
        item.displayed = false;
      });
      section.displayed = true;
      this.displayedResourcesSection = section;
    }
  }

  getOemKey(parent, item) {
    let args = {
      productid: parent.licenseId,
      oemid: item.stringId,
    };
    item.loading = true;
    this.productService.getOemKey(args).subscribe((oemResult: GetOemKeyResponse) => {
      if (oemResult.data) item.data = oemResult.data;
      this.productService.getProducts(true).subscribe((result) => {
        item.loading = false;
      });
    });
  }

  addOptionCard() {
    let regInProcessString;
    this._translate.get("products.register.registration_in_process").subscribe((string) => {
      regInProcessString = string;
    });
    const modalRef = this.appService.showModal(RegisterProductComponent, {
      size: "lg",
      ariaLabelledBy: "modal-title",
      beforeDismiss: () => {
        if (modalRef.componentInstance.registering) {
          alert(regInProcessString);
          return false;
        }
      },
    });
    let asset_ids = [];
    this.group.forEach((item: any) => {
      asset_ids.push({
        serial: item.hardwareSerialNumber,
        asset_id: item.id,
      });
    });
    modalRef.componentInstance.optionCardOptions = {
      asset_ids: asset_ids,
    };
    modalRef.componentInstance.productRegistered.subscribe((result) => {
      // this.setup(true);
    });
    modalRef.result.then(
      (result) => {},
      (error) => {}
    );
  }

  optionCardInstructions(card) {
    const modalRef = this.appService.showModal(FirmwareInstructionsComponent, { size: "lg" });
    modalRef.componentInstance.optionCard = card;
  }

  public removingOptionCard: boolean = false;
  deregisterOptionCard(card) {
    this._translate.get("product_detail.option_cards.confirm_remove").subscribe((string) => {
      this.win.confirm(string).then(
        (_) => {
          this.removingOptionCard = true;
          this.productService.deregisterHardware(card.assetIds[0], true).subscribe((result) => {
            this.removingOptionCard = false;
            this.initView(this.snapshot.product.id);
          });
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  tileClick(prod) {
    this.router.navigate(["products/detail/", prod.id]);
  }

  showiLokOverlay() {
    const modalRef = this.appService.showModal(IlokComponent, { size: "lg" });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  public sidebarWidth: number;
  measureUI() {
    if (this.prodDetailSidebar && this.prodDetailSidebar.nativeElement) {
      this.sidebarWidth = this.prodDetailSidebar.nativeElement.clientWidth - 15;
    }
  }
}
