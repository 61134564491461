<div class="option" tabindex="0" *ngIf="!homepage" (click)="showRegisterUpgradeProduct()">
  <img [src]="'./assets/images/s16-box.png'" style="max-width: 80px" />
  <span class="brandfont-bold plan-title m-b-10">{{ isUpgrade ? "Studio One Pro Upgrade" : "Studio One Pro" }}</span>
  <span class="t-14" *ngIf="isUpgrade">Studio One Pro 7 Perpetual License Upgrade</span>
  <span class="t-14" *ngIf="!isUpgrade">Studio One Pro 7 Perpetual License</span>
  <ng-container *ngIf="discountedMinimumProductPrice">
    <span class="brandfont-bold" [ngStyle]="{ 'text-decoration': 'line-through' }">{{ minimumProductPrice | currency: displayCurrency.toUpperCase() }}</span>
    <span class="brandfont-bold c-link-blue">{{ discountedMinimumProductPrice | currency: displayCurrency.toUpperCase() }}</span>
  </ng-container>
  <ng-container *ngIf="!discountedMinimumProductPrice">
    <span class="t-14 bold d-block m-t-10" *ngIf="minimumProductPrice">{{ minimumProductPrice | currency: displayCurrency.toUpperCase() }}</span>
  </ng-container>
  <span class="brand-btn-reverse d-block m-t-10" data-cy="register-key-button">Buy now</span>
</div>

<div class="align-items-center m-b-50 offer-container" *ngIf="homepage">
  <img
    [src]="env.features.studio_one_seven ? './assets/images/studio-one-7-box-screen.png' : './assets/images/s1-demo.png'"
    class="m-r-20 offer-image"
    style="max-width: 300px; display: inline-block"
    width="100%"
  />
  <div class="offer-text">
    <h5 class="brandfont-bold">{{ title }}</h5>
    <span class="brandfont-black c-link-blue d-block" *ngIf="appliedActiveOffer">{{ appliedActiveOffer.display_message }}</span>
    <span>
      {{ isUpgrade ? "Upgrade to" : "Purchase" }} a Studio One Pro 7 perpetual license and get all the latest features, including AI-powered stem separation, integrated Dolby Atmos mixing and
      rendering, Splice Search with Sound and 2,500+ bundled Splice samples, plus unlimited tracks, FX channels, buses, 8 virtual instruments, and dozens of native effects plug-ins.
    </span>
    <br />
    <br />
    <button class="btn btn-primary" (click)="showRegisterUpgradeProduct()" *ngIf="activeCurrency">
      <ng-container *ngIf="discountedMinimumProductPrice">
        {{ isUpgrading ? "Upgrade" : "Buy now" }}
        <span class="brandfont-bold" [ngStyle]="{ 'text-decoration': 'line-through' }">
          {{ minimumProductPrice | currency: displayCurrency.toUpperCase() }}
        </span>
        <span class="brandfont-black">&nbsp;&nbsp;{{ discountedMinimumProductPrice | currency: displayCurrency.toUpperCase() }}</span>
      </ng-container>
      <ng-container *ngIf="!discountedMinimumProductPrice">
        <span class="t-14 bold d-block" *ngIf="minimumProductPrice">{{ isUpgrading ? "Upgrade" : "Buy now" }} {{ minimumProductPrice | currency: displayCurrency.toUpperCase() }}</span>
      </ng-container>
    </button>
  </div>
</div>
