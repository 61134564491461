import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, CheckoutService, environment, GAService, HelperService, ProductService, UserService, WindowService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterProductComponent, SphereSubscribeStripeComponent, VerifyEmailComponent } from "../modals";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Meta } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductPurchaseUpgradeComponent } from "../product-purchase-upgrade/product-purchase-upgrade.component";

@Component({
  selector: "myp-onboarding-options",
  templateUrl: "onboarding-options.component.html",
})
export class OnboardingOptionsComponent extends BaseComponent implements OnInit {
  public env = environment;
  public subscription: any;
  public couponParam: string;
  public upgradableProducts: any;
  public perpetualDisplayPrice: number;
  public isUpgrade: boolean = false;
  public alreadySubscribed: string;
  public subscribeOnly: boolean = false;
  public headerLogo: string;
  public activeCoupon: any;
  constructor(
    public userService: UserService,
    private _spin: NgxSpinnerService,
    public win: WindowService,
    public translate: TranslateService,
    public gaService: GAService,
    public appService: AppService,
    public router: Router,
    public fbAuth: AngularFireAuth,
    public productService: ProductService,
    public helperService: HelperService,
    public activatedRoute: ActivatedRoute,
    public avoService: AvoService,
    public db: AngularFirestore,
    public metaService: Meta,
    private modalService: NgbModal,
    public fbService: FirebaseService,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.appService.title = "Studio One Pro+ Sign Up — Access Studio One & More";
    this.metaService.addTags([
      {
        name: "Description",
        content:
          "Join Studio One Pro+ for a low monthly membership fee or even lower annual membership and access Studio One Pro 7 music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { itemprop: "name", content: "Studio One Pro+ Sign Up" },
      {
        itemprop: "Description",
        content:
          "Join Studio One Pro+ for a low monthly membership fee or even lower annual membership and access Studio One Pro 7 music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      {
        itemprop: "image",
        content: this.env.features.studio_one_seven
          ? "https://my.presonus.com/angular/assets/images/s17-images/s17-bundle-annual.png"
          : "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png",
      },
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@PreSonus" },
      { name: "twitter:title", content: "Studio One Pro+ Sign Up" },
      {
        name: "twitter:description",
        content:
          "Join Studio One Pro+ for a low monthly membership fee or even lower annual membership and access Studio One Pro 7 music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { name: "twitter:creator", content: "@PreSonus" },
      {
        name: "twitter:image:src",
        content: this.env.features.studio_one_seven
          ? "https://my.presonus.com/angular/assets/images/s17-images/s17-bundle-annual.png"
          : "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png",
      },
      { property: "og:url", content: "https://my.presonus.com/signup" },
      { property: "og:title", content: "Studio One Pro+ Sign Up" },
      {
        property: "og:description",
        content:
          "Join Studio One Pro+ for a low monthly membership fee or even lower annual membership and access Studio One Pro 7 music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { property: "og:site_name", content: "Studio One Pro+ Sign Up" },
      {
        property: "og:image",
        content: this.env.features.studio_one_seven
          ? "https://my.presonus.com/angular/assets/images/s17-images/s17-bundle-annual.png"
          : "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png",
      },
      { property: "fb:app_id", content: "243955558952702" },
      { property: "og:type", content: "website" },
      { property: "og:locale", content: "en_US" },
      { name: "my.presonus", content: "my.presonus" },
      { name: "Presonus register", content: "Presonus register" },
      { name: "Presonus product registration", content: "Presonus product registration" },
      { name: "Mypresonus", content: "Mypresonus" },
      { name: "My presonus", content: "My presonus" },
      { name: "Mypresonus login", content: "Mypresonus login" },
      { name: "My presonus login", content: "My presonus login" },
      { name: "my.presonus login", content: "my.presonus login" },
      { name: "Studio one login", content: "Studio one login" },
      { name: "Studio One Pro+ login", content: "Studio One Pro+ login" },
      { name: "Presonus login", content: "Presonus login" },
      { name: "Log into my presonus", content: "Log into my presonus" },
      { name: "Log into mypresonus", content: "Log into mypresonus" },
      { name: "Studio one free demo", content: "Studio one free demo" },
      { name: "Studio one free", content: "Studio one free" },
      { name: "Studio one demo", content: "Studio one demo" },
      { name: "Studio one prime", content: "Studio one prime" },
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get("subscribeOnly")) this.subscribeOnly = true;
    if (this.activatedRoute.snapshot.queryParamMap.get("registerProduct")) {
      this.showRegisterProduct();
    }
    // this.appService.initTheme("auto");
    this.headerLogo = this.appService.presonusLogo;
    if (this.env.features.studio_one_seven) {
      this.headerLogo = this.subscribeOnly ? this.appService.s1proPlusLogo : this.appService.s1pro7Logo;
    }
    // check query params for term
    if (this.activatedRoute.snapshot.queryParams.term) {
      this._spin.show();
      this.userService.getSubscriptionDetails(true).subscribe({
        next: (result) => {
          if (result.active && result.subscription) this.alreadySubscribed = result.subscription.subscription_levels_id == 1 ? "monthly" : "annual";
          this.continueSphere(this.activatedRoute.snapshot.queryParams.term);
        },
      });
    } else {
      this.userService.getSubscriptionDetails(true).subscribe({
        next: (result) => {
          if (result.active && result.subscription) {
            this.alreadySubscribed = result.subscription.subscription_levels_id == 1 ? "monthly" : "annual";
          }
        },
      });
      this.checkoutService.getUpgradableProducts().subscribe((result) => {
        this.perpetualDisplayPrice = result.data.prices.find((price) => price.currency === "usd").unit_amount / 100;
        this.userService.getActiveOffers().subscribe((activeOffersResult: any) => {
          if (activeOffersResult.data && activeOffersResult.data.length) {
            activeOffersResult.data.forEach((coupon) => {
              let appliesToProducts = coupon.applies_to_products.split(",").map((product) => product.trim());
              if (appliesToProducts.includes(this.checkoutService.upgradableStudioOneProducts?.data?.products[0].stripeProductId)) this.activeCoupon = coupon;
            });
          }
          let amountOff = Math.floor(this.perpetualDisplayPrice * this.activeCoupon?.percentage) / 100;
          if (this.activeCoupon) this.perpetualDisplayPrice = parseFloat((this.perpetualDisplayPrice - amountOff).toFixed(2));
          this.upgradableProducts = result.data;
          this.isUpgrade = this.upgradableProducts.products[0].title.indexOf("Upgrade") > -1;
        });
      });
    }
  }

  goToCheckout(queryParam?) {
    let url = "/checkout";
    if (queryParam) url += "?checkoutProductType=" + queryParam;
    this.appService.goToCheckout(url);
  }

  showRegisterProduct() {
    this.appService.closeModals();
    const modalRef = this.appService.showModal(RegisterProductComponent, {
      size: "lg",
      ariaLabelledBy: "modal-title",
      beforeDismiss: () => {
        if (modalRef.componentInstance.registering) {
          alert(this.translate.instant("products.register.registration_in_process"));
          return false;
        }
      },
    });
    modalRef.componentInstance.autoNavigate = true;
  }

  continueFree() {
    this.appService.closeModals();
    this.appService.initApp();
    this.router.navigate(["/home"]);
  }

  continueSphere(term) {
    this.appService.closeModals();
    if (this.alreadySubscribed) {
      this.router.navigate([`/account/plan`]);
    } else {
      this.appService.goToCheckout("/checkout?term=" + term, this.env.product_ids["sphere_" + term]);
    }
  }

  logout() {
    this.router.navigate(["/logout"]);
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("sphere-color-bg");
    body.classList.remove("full-page");
    this.userService.isInWelcomeFlow = false;
  }
}
