import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { BaseComponent, UserService, ExchangeService, environment, HelperService, CommunityService, ContentService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { FileUploader } from "ng2-file-upload";
import { AdminUploadFailedComponent } from "../admin-upload-failed/admin-upload-failed.component";
import { interval, skip, Subject, takeUntil, throttle } from "rxjs";
import { runInThisContext } from "vm";

@Component({
  selector: "myp-add-edit-product-splash",
  templateUrl: "add-edit-product-splash.component.html",
})
export class AddEditProductSplashComponent extends BaseComponent implements OnInit {
  @Input() splash;
  @Output() splashAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public preview: boolean = false;
  public exists = true;
  public enableProductDownloads: boolean = false;
  public gettingProductDownloadsProduct: boolean = false;
  public enablePreregistrationCustomUI: boolean = false;
  public preregistrationCustomUI: {
    blurb?: string;
    imageUrl?: string;
    allowSkipRegistration?: boolean;
    title?: string;
  } = {};
  public enableRegisterCustomUI: boolean = false;
  public registerCustomUI: {
    blurb?: string;
    hardwareOnly?: boolean;
    headerImage?: string;
    backgroundImage?: string;
  } = {};

  public fileUploadProgress: any = {
    preAuthImageUploader: null,
    prodRegHeaderImageUploader: null,
    prodRegBgImageUploader: null,
  };

  // also downloadsProductId - choose product and store ID there

  public successSplashCollapsed: boolean;
  public preregistrationUICollapsed: boolean;
  public customProductRegistrationUICollapsed: boolean;

  public preAuthImageUploader: FileUploader;
  public prodRegHeaderImageUploader: FileUploader;
  public prodRegBgImageUploader: FileUploader;
  public displayImages: any = {
    preAuthImageUploader: null,
    prodRegHeaderImageUploader: null,
    prodRegBgImageUploader: null,
  };
  public selectedFiles: any = {
    preAuthImageUploader: null,
    prodRegHeaderImageUploader: null,
    prodRegBgImageUploader: null,
  };
  public hoveringFileDrops: any = {
    preAuthImageUploader: false,
    prodRegHeaderImageUploader: false,
    prodRegBgImageUploader: false,
  };

  private _productDownloadsQuery: string;
  private _productDownloadsQueryStream$: Subject<any> = new Subject();
  set productDownloadsQuery(query) {
    this._productDownloadsQuery = query;
    if (this._productDownloadsQuery) {
      this._productDownloadsQueryStream$.next(query);
    } else {
      this.productDownloadsResults = undefined;
    }
  }
  get productDownloadsQuery() {
    return this._productDownloadsQuery;
  }
  public productDownloadsResults: any;

  public selectedDownloadsProduct: any;

  private _associatedProductsQuery: string;
  private _associatedProductsQueryStream$: Subject<any> = new Subject();
  set associatedProductsQuery(query) {
    this._associatedProductsQuery = query;
    if (this._associatedProductsQuery) {
      this._associatedProductsQueryStream$.next(query);
    } else {
      this.associatedProductResults = undefined;
    }
  }
  get associatedProductsQuery() {
    return this._associatedProductsQuery;
  }
  public associatedProductResults: any;

  public stringIds: Array<any>;
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService,
    public contentService: ContentService,
    public productService: ProductService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.splash) {
      this.splash = {};
      this.exists = false;
    }
    if (this.splash.preregistrationCustomUI) {
      this.preregistrationCustomUI = this.splash.preregistrationCustomUI;
      this.enablePreregistrationCustomUI = true;
    }
    if (this.splash.registerCustomUI) {
      this.registerCustomUI = this.splash.registerCustomUI;
      this.enableRegisterCustomUI = true;
    }

    if (this.splash.downloadsProductId) {
      this.enableProductDownloads = true;
      this.gettingProductDownloadsProduct = true;
      this.productService.getProductSnapshot(this.splash.downloadsProductId).subscribe((result) => {
        this.gettingProductDownloadsProduct = false;
        this.selectedDownloadsProduct = result.product;
      });
    }

    this.setupImageUploader("preAuthImageUploader");
    this.setupImageUploader("prodRegHeaderImageUploader");
    this.setupImageUploader("prodRegBgImageUploader");

    this._associatedProductsQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete(true);
      });

    this._productDownloadsQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });
  }

  productAutocomplete(associated?) {
    if (associated) {
      if (this.associatedProductsQuery) {
        this.productService.search(this.associatedProductsQuery).subscribe((result: any) => {
          this.associatedProductResults = result;
        });
      } else {
        this.associatedProductResults = [];
      }
    } else {
      if (this.productDownloadsQuery) {
        this.productService.search(this.productDownloadsQuery).subscribe((result: Array<any>) => {
          this.productDownloadsResults = result;
        });
      } else {
        this.productDownloadsResults = [];
      }
    }
  }

  setAssociatedProd(product) {
    if (!this.splash.string_ids?.length) this.splash.string_ids = [];
    if (!this.splash.string_ids.includes(product.stringId)) {
      this.splash.string_ids.push(product.stringId);
    }
  }

  public settingDownloadsProduct: any;
  setDownloadsProduct(product) {
    this.settingDownloadsProduct = product.id;
    this.productService.getProductSnapshot(product.id).subscribe((result) => {
      this.settingDownloadsProduct = false;
      if (result.downloads?.installers && Object.keys(result.downloads?.installers).length) {
        this.selectedDownloadsProduct = product;
        this.splash.downloadsProductId = product.id;
        this.productDownloadsResults = [];
      } else {
        alert("The product you selected has no downloads associated with it.");
      }
    });
  }

  removeAssociatedProduct(stringId) {
    this.splash.string_ids = this.splash.string_ids.filter((id) => id != stringId);
  }

  removeSelectedDownloadsProduct() {
    this.selectedDownloadsProduct = undefined;
    delete this.splash.downloadsProductId;
  }

  setupImageUploader(type) {
    this[type] = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    this[type].onAfterAddingFile = (file) => {
      file.withCredentials = false;
      if (file.file.type.startsWith("image/")) {
        this.selectedFiles[type] = file;
        this.contentService.uploadSession.filesMap[file.file.name] = { file };
        const reader = new FileReader();

        reader.onload = () => {
          this.displayImages[type] = reader.result; // Set the data URL
        };
        reader.onerror = (error) => {
          console.error("Error reading file", error);
        };
        reader.readAsDataURL(file._file); // Read the file as a data URL

        this.uploadImage(type);
      } else {
        alert("Please select an image file");
        this[type].clearQueue();
      }
    };
    this[type].onProgressAll = (progress: any) => {
      if (this.contentService.uploadSession.filesMap[this.selectedFiles[type].file.name]) {
        this.contentService.uploadSession.filesMap[this.selectedFiles[type].file.name].progress = progress;
      }
      this.fileUploadProgress[type] = progress < 100 ? progress : null;
    };

    this[type].onErrorItem = (error: any) => {
      this.appService.showModal(AdminUploadFailedComponent, { size: "lg" }).componentInstance.data = this.contentService.uploadSession.filesMap[error.file.name];
      delete this.contentService.uploadSession.filesMap[error.file.name];
    };
  }

  fileOverBase(e, type) {
    this.hoveringFileDrops[type] = e;
  }

  removeImage(type) {
    this.displayImages[type] = null;
    this.selectedFiles[type] = null;
    if (type == "preAuthImageUploader") {
      this.preAuthImageUploader.clearQueue();
      delete this.preregistrationCustomUI.imageUrl;
    } else if (type == "prodRegHeaderImageUploader") {
      this.prodRegHeaderImageUploader.clearQueue();
      delete this.registerCustomUI.headerImage;
    } else if (type == "prodRegBgImageUploader") {
      this.prodRegBgImageUploader.clearQueue();
      delete this.registerCustomUI.backgroundImage;
    }
  }

  uploadImage(type) {
    if (this.selectedFiles[type]) {
      this.fileUploadProgress[type] = 1;
      let typeFolder = "preauth-image";
      if (type == "prodRegHeaderImageUploader") typeFolder = "product-registration-header";
      else if (type == "prodRegBgImageUploader") typeFolder = "product-registration-background";
      this.contentService
        .getPresignedUploadURL({
          folder: `product-onboarding/${this.splash.id}/${typeFolder}`,
          fileName: this.selectedFiles[type].file.name,
          type: "generic_file",
        })
        .subscribe((result) => {
          if (result.file) {
            if (type == "preAuthImageUploader") {
              this.preregistrationCustomUI.imageUrl = result.file.url;
            } else if (type == "prodRegHeaderImageUploader") {
              this.registerCustomUI.headerImage = result.file.url;
            } else if (type == "prodRegBgImageUploader") {
              this.registerCustomUI.backgroundImage = result.file.url;
            }
            this.contentService.uploadSession.filesMap[result.file.fileName].paeEntity = result.file;
            this[type].queue.forEach((file) => {
              let uploadHeaders = [];
              uploadHeaders.push({ name: "Content-Type", value: undefined });
              uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + this.selectedFiles[type].file.name + '"' }); // this is breaking upload for some filenames that are non ASCII standard
              file.headers = uploadHeaders;
              file.url = result.url;
              this[type].uploadItem(file);
            });
          } else {
            this.fileUploadProgress[type] = null;
            this.removeImage(type);
            alert("There was an error uploading this file, please try again.");
          }
        });
    }
  }

  togglePreview(type) {
    this.preview = this.preview ? false : type;
    let body = document.getElementsByTagName("body")[0];
    if (this.preview && !this.splash.modalBackground) {
      body.classList.add("clear-modal-content");
    } else {
      body.classList.remove("clear-modal-content");
    }
  }

  submit() {
    if (this.splash.id && this.splash.description) {
      if (confirm("Are you sure you want to add this product splash? If you click ok, it will show for every customer who registers this product after a successful registration.")) {
        let args: any = {
          description: this.splash.description,
          modalBackground: this.splash.modalBackground ? true : false,
          headerText: this.splash.headerText ? this.splash.headerText : "",
          string_ids: this.splash.string_ids,
        };
        if (this.splash.onlyShowDownloadsForUnauth) args.onlyShowDownloadsForUnauth = this.splash.onlyShowDownloadsForUnauth;
        if (this.splash.downloadsProductId && this.enableProductDownloads) args.downloadsProductId = this.splash.downloadsProductId;
        if (this.enablePreregistrationCustomUI) args.preregistrationCustomUI = this.preregistrationCustomUI;
        if (this.enableRegisterCustomUI) args.registerCustomUI = this.registerCustomUI;
        this.communityService.addSplash(this.splash.id, args).then((result) => {
          this.splashAdded.next(true);
          this.activeModal.close();
        });
      }
    } else {
      alert("Please fill out all the required fields.");
    }
  }
}
