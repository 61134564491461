<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Product Onboarding</h4>
  <button class="btn btn-secondary" (click)="editSplash()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="m-b-20">
      Product Onboarding allows you to specify custom behavior for product registration workflow and post registration success messaging. Creating a new onboarding flow will set up a page at
      connect.presonus.com/product/YOUR-UNIQUE-ID that can feature a custom image, description and more. Then, when a user authenticates, you can customize the product registration modal and the
      success screen when they successfully register a product.
    </div>
    <ng-container *ngIf="splashes && splashes.length">
      <ng-container *ngFor="let splash of splashes">
        <myp-list-item [metadata]="getSplashMetadata(splash)" [showImage]="false" [includeChevron]="false" [title]="splash.id" [item]="splash">
          <div class="button-content">
            <fa-icon icon="edit" placement="left" (click)="editSplash(splash)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
            <fa-icon icon="times" placement="left" (click)="deleteSplash(splash)" [ngbTooltip]="'Delete'" class="action circle-icon-btn"></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </ng-container>
  </div>
</div>
