import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from "@angular/core";
import { BaseComponent, CommunityService, EventBusService, Product, ProductService, ProductSnapshot, UserService, WindowService } from "@mypxplat/xplat/core";
import ConfettiGenerator from "confetti-js";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { filter, takeUntil } from "rxjs";

@Component({
  selector: "myp-register-success-splash",
  templateUrl: "register-success-splash.component.html",
})
export class RegisterSuccessSplashComponent extends BaseComponent {
  @Input() splash: any;
  @Input() isNewRegistration: boolean;
  @Input() productId: string;
  @Input() productName: string;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  public removeEventListener: () => void;
  // dont append the string id class for google analytics if we are not showing the splash after a true successful registration.
  public productStringID: string = "not_new_registration";
  public productSnapshot: any;
  public shouldShowDownloads: boolean;
  constructor(
    userService: UserService,
    private _win: WindowService,
    public activeModal: NgbActiveModal,
    public communityService: CommunityService,
    public renderer: Renderer2,
    private elementRef: ElementRef,
    private eventbusService: EventBusService,
    public router: Router,
    public avoService: AvoService,
    public productService: ProductService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.splash.description = this.updateThemeImages(this.splash.description);
    this.handleDownloads();
    this.avoService.avoInitted$
      .pipe(
        filter((result) => !!result),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.avoService.trackEvent().productSplashViewed({
          productId: this.productId ? this.productId.toString() : "",
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          productName: this.productName,
        });
      });
    if (this.isNewRegistration) {
      // splashes that are just for sphere have '_sphere' appended to their string id.
      this.productStringID = this.splash.string_id.split("_sphere")[0];
    }

    if (!this.splash.modalBackground) {
      let body = document.getElementsByTagName("body")[0];
      body.classList.add("clear-modal-content");
    }
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, "click", (event) => {
      this.handleAnchorClick(event);
    });
    this._win.setTimeout(() => {
      var confettiSettings = {
        target: "confetti",
        max: "80",
        size: "1",
        animate: true,
        props: ["circle", "square", "triangle", "line"],
        rotate: true,
        start_from_edge: true,
        respawn: false,
      };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      let closeButton = document.getElementById("close");
      closeButton.onclick = (event) => {
        this.activeModal.close();
      };
    }, 800);
  }

  updateThemeImages(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElement = doc.querySelector("img[data-lighttheme-img][data-darktheme-img]");
    if (imgElement) {
      const theme = this.appService.activeTheme; // 'dark' or 'light'
      const newImageName = theme === "dark" ? imgElement.getAttribute("data-darktheme-img") : imgElement.getAttribute("data-lighttheme-img");
      if (newImageName) {
        // Extract the current src and replace only the image name
        const currentSrc = imgElement.getAttribute("src");
        if (currentSrc) {
          const srcParts = currentSrc.split("/");
          srcParts[srcParts.length - 1] = newImageName; // Replace the last part with the new image name
          imgElement.setAttribute("src", srcParts.join("/"));
        }
      }
    }
    return doc.body.innerHTML;
  }

  handleDownloads() {
    this.shouldShowDownloads = false;
    if (this.splash.downloadsProductId) {
      this.shouldShowDownloads = true;
      if (this.splash.onlyShowDownloadsForUnauth && this.userService.user?.id) this.shouldShowDownloads = false;
    }
    if (this.shouldShowDownloads) {
      this.productService.getHardwareDownloads(this.splash.downloadsProductId).subscribe((productSnapshot: ProductSnapshot) => {
        this.productSnapshot = productSnapshot;
      });
    }
  }

  handleAnchorClick(event) {
    if (event.target && event.target.dataset) {
      if (event.target.dataset.segmentEvent && event.target.dataset.segmentEvent == "productSplashClicked") {
        if (event.target.dataset.segmentSplashClickType && this.avoService.trackEvent && this.avoService.trackEvent().productSplashClicked) {
          this.avoService.trackEvent().productSplashClicked({
            siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
            productId: this.productId ? this.productId.toString() : "",
            productName: this.productName,
            splashClickType: event.target.dataset.segmentSplashClickType,
          });
        }
      }
    }
    if (event.target && event.target.dataset && event.target.dataset.youtubeId) {
      this.eventbusService.emit(this.eventbusService.types.playVideo, {
        id: event.target.dataset.youtubeId,
        type: "youtube",
      });
    } else if (event.target && event.target.dataset && event.target.dataset.productid) {
      event.preventDefault();
      this.close();
      this.router.navigate(["products/detail/", event.target.dataset.productid]);
    }
  }

  close() {
    this.productService.newProductRegistered$.next(true);
    this.closed.next(true);
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.splash = undefined;
    if (this.removeEventListener) this.removeEventListener();
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("clear-modal-content");
  }
}
