<div [class]="productStringID" style="top: 0px; left: 0px; width: 100%; height: 100%; position: fixed"><canvas id="confetti"></canvas></div>
<button
  type="button"
  ngbAutofocus
  *ngIf="!splash.modalBackground || !splash.headerText"
  class="fs-close-btn right border-0 bg-transparent p-0"
  (click)="close()"
  data-cy="close-success-splash-button"
  aria-label="close"
  id="close-success-splash-button"
>
  <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
</button>
<div class="modal-header" *ngIf="splash.modalBackground && splash.headerText">
  <h1 class="modal-title">{{ splash.headerText }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="close()" data-cy="close-success-splash-button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body clear">
  <div class="product-success-splash-content" data-cy="product-success-splash" [innerHTML]="splash.description | safe: 'html'"></div>
  <div class="m-t-30 text-center" *ngIf="shouldShowDownloads && splash.downloadsProductId && !productSnapshot">
    <fa-icon icon="spinner" animation="spin"></fa-icon>
    Loading downloads...
  </div>
  <ng-container *ngIf="productSnapshot?.downloads?.installers">
    <h4 class="section-header m-t-30 justify-content-center">{{ "download.downloads" | translate }}</h4>
    <myp-installer-buttons [centered]="true" *ngIf="productSnapshot?.downloads" [isSideBar]="false" [downloads]="productSnapshot.downloads" [product]="productSnapshot.product"></myp-installer-buttons>
  </ng-container>
  <br />
  <br />
  <div class="text-center"><button class="link" id="close">Close</button></div>
</div>
