import { Component } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { filter, take } from "rxjs";

@Component({
  selector: "myp-notification-preferences",
  templateUrl: "notification-preferences.component.html",
})
export class NotificationPreferencesComponent extends BaseComponent {
  public clients: Array<any> = [
    {
      title: "Email",
      id: "email",
      value: true,
    },
    {
      title: "Push Notifications in the MyPreSonus App (iOS/Android)",
      id: "mobile",
      value: true,
    },
    // {
    //   title: "Notifications in Studio One and other Desktop Apps",
    //   id: "desktop",
    //   value: true,
    // },
  ];
  public showBetaTopics = false;
  public notificationTopics: Array<any> = [
    {
      topic: "collaboration",
      title: "Collaboration",
      description: "These notifications are delivered when workspaces you own or collaborate on are updated.",
      clients: {},
    },
    {
      topic: "community",
      title: "Community",
      description: "These notifications are delivered when other community members interact with you.",
      clients: {},
    },
  ];

  public betaCommunityNotificationPreferences: any = {};

  constructor(
    userService: UserService,
    public communityService: WebCommunityService,
    public betaService: BetaService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.communityService.getFirebaseProfile().then((profile: any) => {
      let isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
      if (isBetaManager) {
        if (profile.betaCommunityNotificationPreferences) this.betaCommunityNotificationPreferences = profile.betaCommunityNotificationPreferences;
        this.betaService.gotBetas$
          .pipe(
            filter((item) => !!item),
            take(1)
          )
          .subscribe((result) => {
            if (this.betaService.enrolledBetas.length > 0) {
              this.showBetaTopics = true;
              this.betaService.enrolledBetas.forEach((id) => {
                if (!this.betaCommunityNotificationPreferences[id]) {
                  this.betaCommunityNotificationPreferences[id] = {
                    new: false,
                    relevant_replies: true,
                    all_replies: false,
                  };
                }
              });
            }
          });
      }

      if (profile.notificationPreferences) {
        for (var i in profile.notificationPreferences) {
          this.notificationTopics.forEach((topic) => {
            if (topic.topic == i) {
              topic.clients = profile.notificationPreferences[i];
            }
          });
          this.notificationTopics.forEach((item) => {
            if (!profile.notificationPreferences[item.topic]) {
              this.clients.forEach((client) => {
                item.clients[client.id] = true;
              });
            }
          });
        }
      } else {
        this.notificationTopics.forEach((item) => {
          this.clients.forEach((client) => {
            item.clients[client.id] = true;
          });
        });
      }
    });
  }

  save() {
    let data = {};
    this.notificationTopics.forEach((item) => {
      data[item.topic] = item.clients;
    });
    let notificationPrefs: any = {
      notificationPreferences: data,
    };
    if (this.showBetaTopics) {
      notificationPrefs.betaCommunityNotificationPreferences = this.betaCommunityNotificationPreferences;
    }
    this.communityService.updateProfile(notificationPrefs).then(() => {
      alert("Preferences saved.");
    });
  }
}
