<div class="edit-profile-container m-r-10 m-b-20">
  <div class="accountinfo">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h1 class="brandfont-bold section-header">Account Notifications</h1>
        Manage your account level notifications by topic. You can choose what type of notifications you'd like to recieve, and how.
        <div class="m-y-20 c-gray" *ngIf="!user.push_token">
          Note: Either you haven't installed the MyPreSonus app, or you haven't accepted Push Notifications. In order to receive push notifications you must have the app installed and have push
          notifications enabled.
          <br />
          <br />
          <img src="./assets/images/mypresonus-app-icon.png" height="50px" alt="MyPreSonus app logo" />
          &nbsp;&nbsp;
          <a href="https://apps.apple.com/us/app/mypresonus/id1282534772" target="_blank"><img src="./assets/images/download-on-the-app-store.png" height="50px" alt="Download on the App Store" /></a>
          &nbsp;&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.presonus.mypresonus&hl=en_US&gl=US" target="_blank">
            <img src="./assets/images/download-on-the-play-store.png" height="50px" alt="Get it on Google Play" />
          </a>
          &nbsp;&nbsp;
        </div>
        <div class="m-t-50">
          <fieldset class="m-y-20">
            <ng-container *ngFor="let topic of notificationTopics">
              <div class="m-b-30">
                <legend class="t-16 bold d-block">{{ topic.title }}</legend>
                {{ topic.description }}
                <br />
                <label class="d-block" *ngFor="let client of clients">
                  <input type="checkbox" [(ngModel)]="topic.clients[client.id]" />
                  &nbsp;
                  <strong>{{ client.title }}</strong>
                </label>
                <div class="t-14" *ngIf="topic.topic == 'collaboration' && !topic.clients.email && !topic.clients.mobile">
                  Warning: Turning off all notifications for collaboration events may mean you'll miss invitations to collaborate, discussions in workspaces or important messages from your
                  collaborators.
                </div>
                <div class="t-12 c-gray" *ngIf="topic.topic == 'collaboration' && (topic.clients.email || topic.clients.mobile)">
                  Some notifications will only be delivered on mobile (if enabled), unless you do not have the app installed.
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showBetaTopics">
              <div class="m-b-30">
                <legend class="t-16 bold d-block">Beta Communities</legend>
                As a Beta Manager, you can choose the level of notifications you would like to receive for the Beta Programs you are enrolled in.
                <br />
                <br />
                Get a notification every time someone...
                <div class="m-b-20" *ngFor="let betaStringId of this.betaService.enrolledBetas">
                  <span class="brandfont-black">{{ betaService.betaDetailByStringId[betaStringId].title }}</span>
                  <label class="d-block">
                    <input type="checkbox" [(ngModel)]="betaCommunityNotificationPreferences[betaStringId].new" />
                    &nbsp;
                    <strong>Adds a post</strong>
                  </label>
                  <label class="d-block">
                    <input type="checkbox" [(ngModel)]="betaCommunityNotificationPreferences[betaStringId].relevant_replies" />
                    &nbsp;
                    <strong>Replies on a post you authored.</strong>
                  </label>
                  <label class="d-block">
                    <input type="checkbox" [(ngModel)]="betaCommunityNotificationPreferences[betaStringId].all_replies" />
                    &nbsp;
                    <strong>Replies to any post.</strong>
                  </label>
                </div>
              </div>
            </ng-container>
            <br />
          </fieldset>
        </div>
        <button class="btn btn-secondary m-b-20" (click)="save()">
          <fa-icon icon="check"></fa-icon>
          &nbsp;Save
        </button>
      </div>
    </div>
  </div>
</div>
