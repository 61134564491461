<div>
  <input
    #messageInput
    [id]="inputID ? inputID : ''"
    *ngIf="!type"
    (blur)="blur.next(true)"
    [type]="type"
    [class]="class"
    [placeholder]="placeholder"
    [(ngModel)]="message"
    (keydown)="keyDownEvent($event)"
    (keyup)="keyUpEvent($event)"
    (paste)="contentPasted($event)"
  />
  <textarea
    #messageInput
    [id]="inputID ? inputID : null"
    *ngIf="type == 'textarea'"
    [class]="class"
    [placeholder]="placeholder"
    [(ngModel)]="message"
    (keydown)="keyDownEvent($event)"
    (keyup)="keyUpEvent($event)"
  ></textarea>

  <div
    *ngIf="type == 'span'"
    #container
    class="editable-message-span-container"
    [class.include-border-top]="backgroundColor == 'transparent'"
    [ngStyle]="{ 'background-color': backgroundColor, 'border-radius': backgroundColor != 'transparent' ? '10px' : '0px' }"
  >
    <ng-container *ngIf="!useQuill">
      <span
        id="comment"
        role="textbox"
        [attr.aria-label]="ariaLabelBy ? null : 'message'"
        [attr.aria-labelledby]="ariaLabelBy ? ariaLabelBy : null"
        [attr.aria-describedby]="ariaDescribedBy ? ariaDescribedBy : null"
        #messageSpan
        [id]="inputID ? inputID : 'input_span'"
        class="editable-message-span"
        (keyup)="keyUpEvent($event)"
        (keydown)="keyDownEvent($event)"
        (paste)="contentPasted($event)"
        (focusin)="focusIn($event)"
        (focusout)="focusOut($event)"
        contenteditable
        [ngStyle]="{ 'margin-right': spanMarginRight + 'px' }"
        [innerText]=""
        [attr.data-placeholder]="placeholder"
      ></span>
    </ng-container>
    <div class="w-full quill-container" *ngIf="useQuill">
      <div [id]="'qe_' + inputID"></div>
      <div [id]="'qt_' + inputID"></div>
    </div>
    <div class="d-flex send-btn-container">
      <img
        src="./assets/icons/workspace-upload.png"
        *ngIf="includeAttachmentButton"
        (click)="attachmentBtnClicked.next()"
        class="attach-btn in-input"
        placement="top"
        [ngbTooltip]="'sphere.workspaces.share_file' | translate"
      />
      <button type="button" class="btn btn-transparent p-0" *ngIf="includeSendButton" (click)="send()" (keyup.enter)="send()" aria-label="send">
        <fa-icon icon="arrow-alt-circle-up" class="send-icon" *ngIf="!sendButtonLoading" aria-hidden="true"></fa-icon>
        <fa-icon icon="spinner" animation="spin" *ngIf="sendButtonLoading" class="send-icon" aria-hidden="true"></fa-icon>
        <span class="sr-only">send</span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="elligibleMentions && elligibleMentions.length">
    <div class="mention-option d-flex m-y-10 align-items-center" [class.selected]="user.selected" (click)="selectMention(user)" *ngFor="let user of elligibleMentions">
      <img [src]="user.photo || './assets/images/default_avatar.jpg'" class="profile-photo tiny m-r-5" />
      <span class="bold t-14">{{ user.name ? user.name : user.firstName + " " + user.lastName }}</span>
    </div>
  </ng-container>
</div>
