import { Directive, Input, OnInit } from "@angular/core";
import { BaseComponent, CommunityService, ContentService, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class VideoNotesBaseComponent extends BaseComponent implements OnInit {
  public videoNotes: Array<any>;
  public timedVideoNotes: Array<any>;
  public note: string;
  @Input() videoId: any;
  public shouldShowActiveTime = false;
  public linkToTimestamp: boolean = true;

  constructor(
    userService: UserService,
    public contentService: ContentService,
    public communityService: CommunityService
  ) {
    super(userService);
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.timedVideoNotes = [];
    this.videoNotes = [];
    if (this.contentService.myVideoNotesByVideoId[this.videoId] && this.contentService.myVideoNotesByVideoId[this.videoId].length) {
      this.contentService.myVideoNotesByVideoId[this.videoId].forEach((note) => {
        this.communityService.buildHtmlComment(note);
      });
      this.videoNotes = this.contentService.myVideoNotesByVideoId[this.videoId].filter((item) => !item.video_timestamp);
      this.timedVideoNotes = this.contentService.myVideoNotesByVideoId[this.videoId].filter((item) => item.video_timestamp);
      if (this.videoNotes && this.videoNotes.length) {
        this.videoNotes.sort((a, b) => {
          return parseInt(a.created) > parseInt(b.created) ? 1 : -1;
        });
      }
      if (this.timedVideoNotes && this.timedVideoNotes.length) {
        this.timedVideoNotes.sort((a, b) => {
          return parseInt(a.video_timestamp) > parseInt(b.video_timestamp) ? 1 : -1;
        });
      }
    }
  }

  addNote(activeTime) {
    if (this.note) {
      let args: any = {
        text: this.note,
        video_id: this.videoId,
      };
      if (this.linkToTimestamp) args.video_timestamp = activeTime?.seconds || 0;
      this.contentService.createVideoNote(args).subscribe((result) => {
        if (!this.contentService.myVideoNotesByVideoId[this.videoId]) this.contentService.myVideoNotesByVideoId[this.videoId] = [];
        this.contentService.myVideoNotesByVideoId[this.videoId].push(result.video_note);
        this.contentService.myVideoNotesMap[result.video_note.id] = result.video_note;
        this.init();
      });
      this.note = "";
    }
  }

  deleteNote(note) {
    this.contentService.deleteVideoNote({ id: note.id }).subscribe((result) => {
      this.contentService.getVideoNotes().subscribe(() => {
        this.init();
      });
    });
  }
}
