<div
  class="list-item hover dontgrow"
  [class.embedded-in-collapsible]="embeddedInCollapsible"
  [class.show-background]="showBackground"
  [ngStyle]="{ 'margin-bottom': marginBottom }"
  [class.hover]="hoverState"
  [class.bottom-border]="includeBottomBorder"
>
  <div
    class="list-item-container"
    [class.align-top]="alignTop"
    [class.expanded]="collapsible && !collapsed"
    (keydown.enter)="itemClick($event); $event.stopPropagation()"
    (keydown.space)="itemClick($event); $event.stopPropagation()"
    (click)="itemClick($event); $event.stopPropagation()"
  >
    <button class="collapse-icon" *ngIf="collapsible">
      <fa-icon [icon]="collapsed ? 'chevron-right' : 'chevron-down'"></fa-icon>
    </button>
    <img
      [src]="photo ? photo : './assets/images/default_avatar.jpg'"
      *ngIf="showImage"
      [ngStyle]="{ opacity: inactive ? '.3' : '1' }"
      class="item-image"
      [class.large]="largeImage"
      [class.round]="imageRound"
      [class.image-border]="imageBorder"
      alt=""
    />
    <fa-icon *ngIf="icon" class="list-item-icon" [icon]="icon"></fa-icon>
    <div class="main-data" (click)="itemClick($event); $event.stopPropagation()" tabindex="0" [attr.role]="href ? 'link' : 'button'">
      <h3 class="title">{{ title }}</h3>

      <div class="sub-data" *ngIf="subDataHtml">
        <ng-container [ngTemplateOutlet]="subDataHtml"></ng-container>
      </div>

      <span class="sub-data" *ngIf="subData && subData.length">
        <div class="muted" *ngFor="let data of subData" [innerHTML]="data"></div>
      </span>
      <div class="metadata" *ngIf="metadata && metadata.length">
        <div class="tag" *ngFor="let data of metadata">
          <span class="title" [ngStyle]="{ color: data.color ? data.color : '' }">{{ data.title }}</span>
          <span class="value" [ngStyle]="{ color: data.color ? data.color : '' }">{{ data.value }}</span>
        </div>
      </div>
    </div>
    <div class="right-data-buttons">
      <ng-container>
        <ng-content select=".button-content"></ng-content>
      </ng-container>

      <fa-icon aria-label="Select" icon="chevron-right" class="m-l-5" *ngIf="includeChevron" [ngbTooltip]="chevronTooltip" aria-hidden="true"></fa-icon>
    </div>
  </div>
  <ng-container *ngIf="collapsible">
    <div class="collapsible" [class.d-none]="collapsed">
      <ng-content select=".collapsible-content"></ng-content>
    </div>
  </ng-container>
</div>
