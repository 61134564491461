import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseComponent, UserService, WindowService, SupportService, ContentService, HelperService, MessageEmbed, WorkspaceService } from "@mypxplat/xplat/core";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import Quill from "quill";

@Component({
  selector: "myp-message-input",
  templateUrl: "message-input.component.html",
})
export class MessageInputComponent extends BaseComponent {
  public message: string;
  public highlightedMention: any;
  public selectedMentionsInMessage: Array<any> = [];
  public elligibleMentions: Array<any> = [];
  public spanMarginRight: number = 0;
  public selectedArticlesInMessage: Array<any> = [];
  public selectedCategoriesSectionsInMessage: Array<any> = [];
  public selectedLearnContentInMessage: Array<any> = [];
  public publicFileLinksInMessage: Array<any> = [];
  public messageEmbeds: Array<MessageEmbed> = [];
  @Input() class: string;
  @Input() inputID: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() ariaLabelBy: string;
  @Input() ariaDescribedBy: string;
  @Input() includeSendButton: boolean = false;
  @Input() includeAttachmentButton: boolean = false;
  @Input() mentionSource: any;
  @Input() mentionDataSource: Array<any>;
  @Input() shouldDetectContent: boolean;
  @Input() collaborators: Array<any>;
  @Input() useQuill: boolean;
  @Input() backgroundColor: string = "transparent";
  @Input() sendButtonLoading: boolean;
  @Output() returnPressed: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() keyUp: EventEmitter<any> = new EventEmitter();
  @Output() keyDown: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  @Output() messageSent: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() inputHeightChange: EventEmitter<any> = new EventEmitter();
  @Output() attachmentBtnClicked: EventEmitter<any> = new EventEmitter();
  @Output() contentDetected: EventEmitter<any> = new EventEmitter();
  @Output() mentionSelected: EventEmitter<any> = new EventEmitter();
  public displayPlaceholder: boolean = true;
  public gettingFileDetails: any;
  public containerHeight: number;
  @ViewChild("container", { static: false }) container: ElementRef;
  @ViewChild("messageInput", { static: false }) messageInput: ElementRef;
  @ViewChild("messageSpan", { static: false }) messageSpan: ElementRef;

  public controlDepressed: boolean = false;
  public quill: Quill;
  constructor(
    public userService: UserService,
    private _win: WindowService,
    public communityService: WebCommunityService,
    public supportService: SupportService,
    public contentService: ContentService,
    public helperService: HelperService,
    public workspaceService: WorkspaceService,
    private sanitizer: DomSanitizer
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loaded.next({ id: this.inputID, cmp: this });
    if (this.includeSendButton && this.includeAttachmentButton) {
      this.spanMarginRight = 70;
    } else if (this.includeSendButton || this.includeAttachmentButton) {
      this.spanMarginRight = 35;
    }
  }

  ngAfterViewInit() {
    this._win.setTimeout(() => {
      if (this.container && this.container.nativeElement) {
        this.containerHeight = this.container.nativeElement.clientHeight;
      }
      if (this.useQuill) this.initQuill();
    }, 300);
  }

  initQuill() {
    const toolbarOptions = [["bold", "italic", "underline", "strike"], ["blockquote", "code-block"], [{ list: "ordered" }, { list: "bullet" }], ["link"], ["clean"]];

    const customBindings = {
      enter: {
        key: "Enter",
        ctrlKey: true,
        handler: (range, context) => {
          this.send();
        },
      },
    };
    this.quill = new Quill("#qe_" + this.inputID, {
      modules: {
        toolbar: toolbarOptions,
        keyboard: {
          bindings: customBindings,
        },
      },
      placeholder: "What would you like to say?",
      theme: "snow",
    });
    // Move the toolbar into the custom container
    const toolbar = (<any>this.quill.getModule("toolbar")).container;
    const customToolbarContainer = document.getElementById("qt_" + this.inputID);
    customToolbarContainer.appendChild(toolbar);

    this.quill.on("text-change", (delta, oldDelta, source) => {
      const htmlContent = this.quill.root.innerHTML;
      this.valueChanged.next(htmlContent);
    });
    this.quill.root.addEventListener("focus", () => {
      this.focus.next(true);
    });

    this.quill.root.addEventListener("blur", () => {
      this.blur.next(true);
    });
  }

  emptyInput() {
    this.message = "";
    let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
    if (messageSpan) messageSpan.innerHTML = "";
    this.displayPlaceholder = false;
    this.elligibleMentions = [];
    if (this.quill) this.quill.setText("");
    this.evalContainerHeight();
  }

  keyUpEvent(args) {
    if (this.type == "span") {
      let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
      this.message = messageSpan.innerHTML;
      if (args.key == "Control") this.controlDepressed = false;
      this.displayPlaceholder = this.message ? false : true;
    }
    if (args.key == "Enter" || args.key == "Return" || args.keyCode == 13) {
      if (this.type != "span" || (this.type == "span" && this.controlDepressed)) {
        this.keyUp.next({ event: args, message: this.message });

        if (this.mentionSource) this.mentionKeyUp({ event: args, message: this.message });
        this.returnPressed.next({ value: this.message, event: args, cmp: this });
        this.messageSent.next({ value: this.message, event: args, cmp: this });
        this.controlDepressed = false;
      } else {
        this.keyUp.next({ event: args, message: this.message });
        if (this.shouldDetectContent) this.detectContent();
        if (this.elligibleMentions && this.elligibleMentions.length) args.preventDefault();
      }
    } else {
      this.keyUp.next({ event: args, message: this.message });
      if (this.shouldDetectContent) this.detectContent();
      if (this.mentionSource) this.mentionKeyUp({ event: args, message: this.message });
      this.valueChanged.next(this.message);
    }

    if (!this.type) args.preventDefault();
    this.evalContainerHeight();
  }

  detectContent() {
    this._win.setTimeout(() => {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      if (this.message.indexOf("support/articles") > -1) {
        // https://sphere.presonus.com/support/articles?category=9528950167821&section=9530420849165&article=9041673905165
        let categoryId, sectionId, articleId, shareHash;
        let myUrl = this.message.match(urlRegex) && this.message.match(urlRegex)[0] ? this.message.match(urlRegex)[0] : "";
        if (myUrl.indexOf("&nbsp;")) myUrl = myUrl.replace("&nbsp;", "");
        if (myUrl) {
          let paramsStringParts = myUrl.split("&amp;");
          paramsStringParts.forEach((part) => {
            if (part.indexOf("category") > -1) {
              categoryId = part.split("=")[1];
            } else if (part.indexOf("section") > -1) {
              sectionId = part.split("=")[1];
            } else if (part.indexOf("article") > -1) {
              articleId = part.split("=")[1];
            }
          });
          if (articleId && sectionId) {
            // get the section, which will come with the article.
            this.supportService.getArticlesBySection(sectionId).subscribe((result) => {
              let article = result.filter((art) => art.id == articleId)[0];
              if (article) {
                let replaceHtml = '<span contenteditable="false" class="inline-article" data-articleid="' + articleId + '" data-sectionid="' + sectionId + '">' + article.name + "</span>";
                if (!this.selectedArticlesInMessage.filter((item) => article.id == item.article.id).length) {
                  this.selectedArticlesInMessage.push({ selector: article.name, editorSelector: replaceHtml, article: article });
                }
                this.contentDetected.next({ selector: article.name, editorSelector: replaceHtml, article: article });
                this.setMessageValue(this.message.replace(myUrl, replaceHtml));
                this.valueChanged.next(this.message);
                this.cursorToEnd(50);
              }
            });
          } else if (sectionId && categoryId) {
            this.supportService.getSectionsByCategoryId(categoryId).subscribe((result) => {
              let section = result.filter((item) => item.id == sectionId)[0];
              if (section) {
                let replaceHtml = '<span contenteditable="false" class="inline-article" data-categoryid="' + categoryId + '" data-sectionid="' + sectionId + '">' + section.name + "</span>";
                if (!this.selectedCategoriesSectionsInMessage.filter((item) => section.id == item.category_or_section.id).length) {
                  this.selectedCategoriesSectionsInMessage.push({ selector: section.name, editorSelector: replaceHtml, category_or_section: section });
                }
                this.contentDetected.next({ selector: section.name, editorSelector: replaceHtml, category_or_section: section });
                this.setMessageValue(this.message.replace(myUrl, replaceHtml));
                this.valueChanged.next(this.message);
                this.cursorToEnd(50);
              }
            });
          } else if (categoryId) {
            this.supportService.getCategories().subscribe((result: any) => {
              let category = result.filter((item) => item.id == categoryId)[0];
              if (category) {
                let replaceHtml = '<span contenteditable="false" class="inline-article" data-categoryid="' + categoryId + '">' + category.name + "</span>";
                if (!this.selectedCategoriesSectionsInMessage.filter((item) => category.id == item.category_or_section.id).length) {
                  this.selectedCategoriesSectionsInMessage.push({ selector: category.name, editorSelector: replaceHtml, category_or_section: category });
                }
                this.contentDetected.next({ selector: category.name, editorSelector: replaceHtml, category_or_section: category });
                this.setMessageValue(this.message.replace(myUrl, replaceHtml));
                this.valueChanged.next(this.message);
                this.cursorToEnd(50);
              }
            });
          }
        }
      } else if (this.message.indexOf("viewingVideo=") > -1) {
        let myUrl = this.message.match(urlRegex) && this.message.match(urlRegex)[0] ? this.message.match(urlRegex)[0] : "";
        if (myUrl.indexOf("&nbsp;")) myUrl = myUrl.replace("&nbsp;", "");
        let videoId = myUrl.split("viewingVideo=")[1];
        let video = this.contentService.videosById[videoId];
        if (video) {
          let replaceHtml = '<span contenteditable="false" class="inline-video" data-videoid="' + video.id + '">' + video.title + "</span>";
          if (!this.selectedLearnContentInMessage.filter((item) => video.id == item.content.id).length) {
            this.selectedLearnContentInMessage.push({ selector: video.title, editorSelector: replaceHtml, content: video });
          }
          this.contentDetected.next({ selector: video.title, editorSelector: replaceHtml, content: video });
          this.setMessageValue(this.message.replace(myUrl, replaceHtml));
          this.valueChanged.next(this.message);
          this.cursorToEnd(50);
        }
      } else if (this.message.indexOf("/lesson/") > -1 || this.message.indexOf("/course/") > -1) {
        let myUrl = this.message.match(urlRegex) && this.message.match(urlRegex)[0] ? this.message.match(urlRegex)[0] : "";
        if (myUrl.indexOf("&nbsp;")) myUrl = myUrl.replace("&nbsp;", "");
        let isLesson = myUrl.split("/lesson/").length > 1;
        let itemID = isLesson ? myUrl.split("/lesson/")[1] : myUrl.split("/course/")[1];
        let item = this.contentService[isLesson ? "lessonMap" : "courseMap"][itemID];
        if (item) {
          let replaceHtml = '<span contenteditable="false" class="inline-lesson" data-lessonid="' + item.id + '">' + item.title + "</span>";
          if (!this.selectedLearnContentInMessage.filter((item) => item.id == item.content.id).length) {
            this.selectedLearnContentInMessage.push({ selector: item.title, editorSelector: replaceHtml, content: item });
          }
          this.contentDetected.next({ selector: item.title, editorSelector: replaceHtml, content: item });
          this.setMessageValue(this.message.replace(myUrl, replaceHtml));
          this.valueChanged.next(this.message);
          this.cursorToEnd(50);
        }
      } else if (this.message.indexOf("/share/") > -1) {
        let myUrl = this.message.match(urlRegex) && this.message.match(urlRegex)[0] ? this.message.match(urlRegex)[0] : "";
        if (myUrl.indexOf("&nbsp;")) myUrl = myUrl.replace("&nbsp;", "");
        let shareHash = myUrl.split("/share/")[1];
        if (shareHash && !this.gettingFileDetails) {
          this.gettingFileDetails = shareHash;
          this.workspaceService.getFileDetails(false, shareHash).subscribe({
            next: (file: any) => {
              this.gettingFileDetails = undefined;
              file.hash = shareHash;
              let replaceHtml = '<span contenteditable="false" class="inline-share" data-shareid="' + file.hash + '">' + file.filename + "</span>";
              if (!this.publicFileLinksInMessage.filter((item) => item.publicFile.hash == file.hash).length) {
                this.publicFileLinksInMessage.push({ selector: file.filename, editorSelector: replaceHtml, publicFile: file });
              }
              this.contentDetected.next({ selector: file.filename, editorSelector: replaceHtml, publicFile: file });
              this.setMessageValue(this.message.replace(myUrl, replaceHtml));
              this.valueChanged.next(this.message);
              this.cursorToEnd(50);
            },
            error: (err) => {
              this.gettingFileDetails = undefined;
            },
          });
        }
      }

      const newEmbeds = this.communityService.parseEmbedUrls(this.message, this.messageEmbeds);
      if (newEmbeds.length > 0) {
        this.messageEmbeds = this.messageEmbeds.concat(newEmbeds);
      }
    }, 30);
  }

  removeSelectedArticle(article) {
    this.selectedArticlesInMessage = this.selectedArticlesInMessage.filter((item) => item.article.id != article.article.id);
    if (this.message && article.selector && this.message.indexOf(article.selector) > -1) {
      this.setMessageValue(this.message.replace(article.editorSelector, ""));
      this.cursorToEnd(50);
    }
  }

  removeSelectedLearnContent(item) {
    this.selectedLearnContentInMessage = this.selectedLearnContentInMessage.filter((item) => item.content.id != item.content.id);
    if (this.message && item.selector && this.message.indexOf(item.selector) > -1) {
      this.setMessageValue(this.message.replace(item.editorSelector, ""));
      this.cursorToEnd(50);
    }
  }

  removeEmbed(embed) {
    this.messageEmbeds = this.messageEmbeds.filter(({ id }) => id != embed.id);
    if (this.message && embed.pastedUrl && this.message.includes(embed.pastedUrl)) {
      this.setMessageValue(this.message.replace(embed.pastedUrl, ""));
      this.cursorToEnd(50);
    }
  }

  removePublicFile(file) {
    this.publicFileLinksInMessage = this.publicFileLinksInMessage.filter((item) => item.publicFile.id != file.publicFile.id);
    if (this.message && file.selector && this.message.indexOf(file.selector) > -1) {
      this.setMessageValue(this.message.replace(file.editorSelector, ""));
      this.cursorToEnd(50);
    }
  }

  contentPasted(e) {
    e.preventDefault();
    let content;
    this.displayPlaceholder = false;
    if (e.clipboardData) {
      content = (e.originalEvent || e).clipboardData.getData("text/plain");
      document.execCommand("insertText", false, content);
    }
    let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
    this.message = messageSpan.innerHTML;
    this.valueChanged.next(this.message);
  }

  evalContainerHeight() {
    this._win.setTimeout(() => {
      if (this.container && this.container.nativeElement) {
        if (this.container.nativeElement.clientHeight != this.containerHeight) {
          this.containerHeight = this.container.nativeElement.clientHeight;
          this.inputHeightChange.next(this.containerHeight);
        }
      }
    }, 50);
  }

  keyDownEvent(args) {
    if (this.type == "span" && args.key == "Control") this.controlDepressed = true;
    if (this.type == "span" && (args.key == "Enter" || args.key == "Return" || args.keyCode == 13)) {
      if (this.controlDepressed) {
        args.preventDefault();
      } else {
        this.keyDown.next({ event: args, message: this.message });
        if (this.mentionSource) this.mentionKeyDown({ event: args, message: this.message });
      }
    } else {
      this.keyDown.next({ event: args, message: this.message });
      if (this.mentionSource) this.mentionKeyDown({ event: args, message: this.message });
    }
  }

  focusIn(args) {
    this.focus.next(true);
    this.cursorToEnd();
  }

  focusOut(args) {
    if (this.message && !this.message.trim()) this.message = undefined;
    if (!this.message) this.displayPlaceholder = true;
    let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
    if (this.message != messageSpan.innerHTML) {
      this.message = messageSpan.innerHTML;
      this.valueChanged.next(this.message);
    }
    this.blur.next(true);
  }

  controlEnterEvent(event) {
    this.keyUp.next({ event: event, message: this.message });
    this.returnPressed.next({ value: this.message, event: event, cmp: this });
    event.preventDefault();
  }

  send() {
    if (!this.sendButtonLoading) {
      if (this.useQuill) {
        this.message = this.quill.root.innerHTML;
      } else {
        let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
        this.message = messageSpan.innerHTML;
      }
      this.valueChanged.next(this.message);
      this.returnPressed.next({ value: this.message, event: false, cmp: this });
      this.messageSent.next({ value: this.message, event: false, cmp: this });
    }
  }

  setMessageValue(value, focus = true) {
    this.message = value;
    this.displayPlaceholder = this.message ? false : true;
    if (this.useQuill) {
      this._win.setTimeout(() => {
        const delta = this.quill.clipboard.convert({ html: value });
        this.quill.setContents(delta);
      }, 300);
    } else {
      let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
      if (messageSpan) messageSpan.innerHTML = "";
      this._win.setTimeout(() => {
        if (value && this.messageInput && this.messageInput.nativeElement) this.messageInput.nativeElement.focus();
        if (messageSpan) {
          messageSpan.innerHTML = value;
          if (value && focus) messageSpan.focus();
        }
        this.evalContainerHeight();
      }, 50);
    }
  }

  focusMessageInput() {
    if (this.useQuill) {
      this.quill.focus();
    } else {
      let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
      if (messageSpan) messageSpan.focus();
      this.cursorToEnd();
    }
  }

  cursorToEnd(timeout = 10) {
    this._win.setTimeout(() => {
      let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
      if (messageSpan) {
        messageSpan.focus();
        var range = document.createRange();
        range.selectNodeContents(messageSpan);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        sel.collapseToEnd();
        messageSpan.focus();
      }
      this.displayPlaceholder = false;
    }, timeout);
  }

  autocompleteMemberSearch(query, limit = 5) {
    if (query) {
      return this.communityService.findMembers(false, query.toLowerCase(), false, limit, true).then((result) => {
        if (result && result[0]) result[0].selected = true;
        return result;
      });
    }
  }

  selectMention(user, noQuery = false) {
    let replaceHtml = '<span contenteditable="false" class="inline-mention" data-mentionid="' + user.id + '">@' + (user.name ? user.name : user.firstName + " " + user.lastName) + "</span>&nbsp;";
    if (noQuery) {
      this.setMessageValue(replaceHtml);
    } else {
      this.setMessageValue(this.message.replace("@" + this.mentionQuery, replaceHtml));
    }
    this.displayPlaceholder = false;
    this.valueChanged.next(this.message);
    setTimeout(() => {
      this.focusMessageInput();
    }, 250);
    this.selectedMentionsInMessage.push({
      user: user,
      editorSelector: replaceHtml,
      selector: "@" + (user.name ? user.name : user.firstName + " " + user.lastName),
    });
    this.mentionSelected.next({
      user: user,
      id: user.id,
      editorSelector: replaceHtml,
      selector: "@" + (user.name ? user.name : user.firstName + " " + user.lastName),
    });
    this.elligibleMentions = [];
    this.isMentioning = false;
    this.mentionQuery = "";
    this.elligibleMentions = [];
  }

  public isMentioning: boolean = false;
  public mentionQuery: string = "";
  mentionKeyUp(args) {
    if (args.message == "" || args.message == "@") {
      this.elligibleMentions = [];
    }
    if (args.event.key == "Delete" || args.event.key == "Backspace") {
      this.selectedMentionsInMessage.forEach((item, index) => {
        if (this.message.indexOf(item.selector) == -1) {
          this.selectedMentionsInMessage.splice(index, 1);
        }
      });
    }
    if (args.event.key == "ArrowDown" || args.event.key == "ArrowUp") {
      let selectedElligibleMentionIndex;
      this.elligibleMentions.forEach((item, index) => {
        if (item.selected) selectedElligibleMentionIndex = index;
        item.selected = false;
      });

      if (this.elligibleMentions[selectedElligibleMentionIndex + (args.event.key == "ArrowUp" ? -1 : 1)]) {
        this.elligibleMentions[selectedElligibleMentionIndex + (args.event.key == "ArrowUp" ? -1 : 1)].selected = true;
      } else {
        if (this.elligibleMentions[args.event.key == "ArrowUp" ? this.elligibleMentions.length - 1 : 0])
          this.elligibleMentions[args.event.key == "ArrowUp" ? this.elligibleMentions.length - 1 : 0].selected = true;
      }
    } else if (args.key == "Enter" || args.key == "Return" || args.keyCode == 13) {
      if (this.elligibleMentions.length) {
        this.elligibleMentions.forEach((item) => {
          if (item.selected) this.selectMention(item);
        });
        args.event.preventDefault();
      }
    } else {
      if (this.getCharacterPrecedingCaret() == "@") {
        this.mentionQuery = "";
        this.isMentioning = true;
      }

      if (this.isMentioning) {
        if (this.helperService.acceptableMentionKeys[args.event.key.toLowerCase()]) {
          // only append alphanumeric keys and spaces to mention query
          this.mentionQuery += args.event.key == " " ? " " : args.event.key;
        } else if (args.event.key == "Backspace") {
          this.mentionQuery = this.mentionQuery.slice(0, -1);
          if (this.mentionQuery == "") {
            this.isMentioning = false;
            this.elligibleMentions = [];
          }
        } else if (args.event.key == "Delete") {
          // if the user deletes a character, its hard to know where in the string they are deleting,
          // so cancel.
          this.isMentioning = false;
          this.mentionQuery = "";
          this.elligibleMentions = [];
        }
        if (this.mentionQuery.match(/([\s]+)/g) && this.mentionQuery.match(/([\s]+)/g).length > 1) {
          // if the user enters two spaces, cancel the mention.
          this.isMentioning = false;
          this.mentionQuery = "";
          this.elligibleMentions = [];
        }
        if (this.mentionQuery) {
          if (this.mentionSource == "community_profiles") {
            this.autocompleteMemberSearch(this.mentionQuery).then((results) => {
              this.elligibleMentions = results;
            });
          } else if (this.mentionSource && this.mentionDataSource) {
            this.elligibleMentions = this.mentionDataSource.filter((item) => item.name && item.name.toLowerCase().indexOf(this.mentionQuery.toLowerCase()) > -1);
          }
        }
      }
    }
  }

  getCharacterPrecedingCaret() {
    let messageSpan = document.getElementById(this.inputID ? this.inputID : "input_span");
    var precedingChar = "",
      sel,
      range,
      precedingRange;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0).cloneRange();
        range.collapse(true);
        range.setStart(messageSpan, 0);
        precedingChar = range.toString().slice(-1);
      }
    } else if ((sel = (<any>document).selection) && sel.type != "Control") {
      range = sel.createRange();
      precedingRange = range.duplicate();
      precedingRange.moveToElementText(messageSpan);
      precedingRange.setEndPoint("EndToStart", range);
      precedingChar = precedingRange.text.slice(-1);
    }
    return precedingChar;
  }

  mentionKeyDown(args) {
    if (args.event.key == "Enter" || args.event.key == "Return" || args.event.keyCode == 13 || args.event.key == "Tab") {
      if (this.elligibleMentions && this.elligibleMentions.length) {
        this.elligibleMentions.forEach((item) => {
          if (item.selected) this.selectMention(item);
        });
        if (args && args.event && args.event.preventDefault) args.event.preventDefault();
      }
    }
    if (args.event.keyCode == 9) this.mentionKeyUp(args);
  }
}
