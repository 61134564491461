import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BaseComponent, UserService, EventBusService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { format, getDaysInMonth } from "date-fns";

@Component({
  selector: "myp-date-picker",
  templateUrl: "date-picker.component.html",
})
export class DatePickerComponent extends BaseComponent implements OnInit {
  public years: Array<string> = [];
  public months: Array<string> = ["January", "February", "March", "April", "May", "June", "July ", "August", "September", "October", "November", "December"];
  public days: Array<string> = [];

  @Input() initWithDate: Date = new Date();
  @Input() earliestYear: number = 1969;
  @Input() latestYear: number;

  public selectedYear: string = new Date().getFullYear().toString();
  public selectedMonth: string = this.months[new Date().getMonth()];
  public selectedDay: string = new Date().getDate().toString();

  public prettySelectedDate: string;
  private _selectedDate: Date;
  set selectedDate(date) {
    if (date) {
      this.prettySelectedDate = format(date, "MMMM do yyyy");
      this._selectedDate = date;
    }
  }

  get selectedDate() {
    return this._selectedDate;
  }

  @Input() public past: boolean = true;
  @Output() dateSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    userService: UserService,
    private _eventBus: EventBusService,
    public activeModal: NgbActiveModal
  ) {
    super(userService);
  }

  ngOnInit() {
    this.selectedYear = this.initWithDate.getFullYear().toString();
    this.selectedMonth = this.months[this.initWithDate.getMonth()];
    this.selectedDay = this.initWithDate.getDate().toString();
    for (let i = this.earliestYear; i < (this.past ? (this.latestYear ? this.latestYear : new Date().getFullYear() + 1) : 2030); i++) {
      this.years.push(i.toString());
    }
    this.years.reverse();
    this.populateDays(parseInt(this.selectedYear), this.months.indexOf(this.selectedMonth));
    this.selectedDate = new Date(parseInt(this.selectedYear), this.months.indexOf(this.selectedMonth), parseInt(this.selectedDay));
  }

  select(value, type) {
    if (type == "year") {
      this.selectedYear = value;
      this.populateDays(parseInt(this.selectedYear), this.months.indexOf(this.selectedMonth));
    } else if (type == "month") {
      this.selectedMonth = value;
      this.populateDays(parseInt(this.selectedYear), this.months.indexOf(this.selectedMonth));
    } else if (type == "day") {
      this.selectedDay = value;
    }

    if (this.selectedYear && this.selectedMonth && this.selectedDay) this.selectedDate = new Date(parseInt(this.selectedYear), this.months.indexOf(this.selectedMonth), parseInt(this.selectedDay));
  }

  done() {
    this.dateSelected.next(this.selectedDate);
    this.close();
  }

  populateDays(year: number, month: number) {
    this.days = [];
    if (!year) year = new Date().getFullYear();
    if (!month && month !== 0) month = new Date().getMonth() + 1;
    const date = new Date(year, month, 1);
    const numberOfDays = getDaysInMonth(date);
    this.days = [];
    for (let i = 1; i <= numberOfDays; i++) {
      this.days.push(i.toString());
    }
  }

  close(event?) {
    if (event) event.stopPropagation();
    this.activeModal.close();
  }
}
